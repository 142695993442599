'use client'
import { Separator } from '@repo/ui/components/ui/separator'

import NotificationNav from '../custom/notificationNav'
import { UserNav } from '../custom/userMenu'
import { FeedbackCard } from '../custom/feedbackCard'
import { HelpCard } from '../custom/helpCard'

const Navbar = ({ title }: { title?: string }) => {
    return (
        <div className="sticky top-0 bg-background z-50 h-fit">
            <div className="flex justify-between px-3 py-2 items-center">
                <div className="h-9 flex item-center">
                    <h1 className="font-semibold text-lg leading-none my-auto">
                        {title || ''}
                    </h1>
                </div>
                <div className="flex items-center gap-2">
                    {/* <Link
                        to="/"
                        className={cn(
                            buttonVariants({
                                variant: 'ghost',
                                size: 'sm',
                            })
                        )}
                    >
                        <span>Help</span>
                    </Link>  */}
                    <HelpCard trigger="textButton"/>
                    <FeedbackCard/>
                    <NotificationNav />
                    <UserNav />
                </div>
            </div>
            <Separator />
        </div>
    )
}

export default Navbar
