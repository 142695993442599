import { OrgTicketsTable } from '@repo/types/db'
import { cn } from '@ui/lib/utils'

import { CustomizedLegend } from '../charts/legend'
import { PieChart } from '../charts/pieChart'

const TicketChart = ({
    data,
}: {
    data: Record<OrgTicketsTable['status'], number>
}) => {
    const pieData: Array<{
        name: OrgTicketsTable['status']
        value: number
        color: string
    }> = data && [
        {
            name: 'open',
            value: data['open'] || 0,
            color: 'hsl(var(--primary) / 0.2)',
        },
        {
            name: 'escalated',
            value: data['escalated'] || 0,
            color: 'hsl(var(--primary) / 0.5)',
        },
        {
            name: 'closed',
            value: data['closed'] || 0,
            color: 'hsl(var(--primary) / 0.8)',
        },
    ]

    return (
        <div
            className={cn('col-span-3 border rounded-md p-4 grid  bg-muted/40')}
        >
            <h3 className="text-lg">Tickets</h3>
            <div className="mt-auto">
                <div className="flex relative">
                    <div className="w-1/3">
                        {pieData && <CustomizedLegend payload={pieData} />}
                    </div>
                    <div className="flex-1 relative">
                        <div className="absolute bottom-0 w-full right-0">
                            {pieData && <PieChart data={pieData} type={'ticket'}/>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TicketChart
