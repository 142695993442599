import { cn } from '@repo/ui/lib/utils'

import { formatNumberToReadable } from '@/lib/number'

export const CustomizedTooltip = (props: any) => {
    const { active, payload, className } = props
    if (active && payload && payload.length) {
        return (
            <div
                className={cn(
                    'w-fit p-1 min-w-24 backdrop-blur-sm border border-border/40 bg-white/50 shadow-lg text-foreground rounded-md text-sm',
                    className
                )}
            >
                <p className="capitalize">{payload[0].payload.name}</p>
                <p>{formatNumberToReadable(payload[0].value)}</p>
            </div>
        )
    }

    return null
}
