// src/routes/posts.tsx

import { useEffect } from 'react'
import { emitMessage } from '@/emitters'
import { singleOrgQueryOptions } from '@/queries/orgs'
import { useGlobalStore } from '@/store/global'
import { cn } from '@repo/ui/lib/utils'
import { useSuspenseQuery } from '@tanstack/react-query'
import {
    createFileRoute,
    Link,
    Navigate,
    Outlet,
    // useNavigate,
    useRouter,
} from '@tanstack/react-router'

import { checkAllowedAgentRoutes } from '@/lib/allowedRoutes'
import { buttonVariants } from '@repo/ui/components/ui/button'
import ErrorPage from '@/components/custom/errorPage'
import { FullPageLoader } from '@/components/custom/loader'
import NotFoundPage from '@/components/custom/notfound'

export const Route = createFileRoute('/_dashboard/org/$orgId')({
    loader: (opts) => {
        const { org } = useGlobalStore.getState()
        if (!org) {
            opts.navigate({ to: '/orgs', replace: true })
        } else {
            opts.params.orgId &&
                opts.context.queryClient?.ensureQueryData(
                    singleOrgQueryOptions({
                        orgId: opts.params.orgId,
                    })
                )
        }
        return
    },
    pendingComponent: () => {
        return <FullPageLoader />
    },
    errorComponent: (err) => {
        return (
            <ErrorPage message={(err.error as any)?.message}>
                <Link to={'/orgs'} className={cn(buttonVariants())}>
                    <span>View All Organizations</span>
                </Link>
            </ErrorPage>
        )
    },

    // eslint-disable-next-line no-use-before-define
    component: Component,
})

function Component() {
    const { orgId } = Route.useParams()
    const { data } = useSuspenseQuery(singleOrgQueryOptions({ orgId }))
    const { update } = useGlobalStore()
    // const navigate = useNavigate()

    const router = useRouter()
    useEffect(() => {
        update({ org: data?.id || undefined })
        // if (!data) navigate({ to: '/orgs', replace: true })
    }, [data])

    const handleRoomJoin = (join: boolean) => {
        emitMessage('org:action', { orgId, action: join ? 'join' : 'leave' })
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            handleRoomJoin(true)
        }, 500)

        return () => {
            clearTimeout(timer)
            handleRoomJoin(false)
        }
    }, [orgId])

    if (!data) return <Navigate to="/orgs" />
    if (
        data.member_role === 'agent' &&
        !checkAllowedAgentRoutes({
            orgId,
            pathname: router.latestLocation.pathname,
        })
    )
        return <NotFoundPage />
    return <Outlet />
}
