import { formatNumberToReadable } from '@/lib/number'

export const CustomizedLegend = (props: any) => {
    const { payload } = props
    return (
        <ul className="text-xs space-y-1">
            {payload.map((entry: any, index: number) => (
                <li
                    key={`item-${index}`}
                    className="flex items-center justify-start gap-2"
                >
                    <span
                        className={'w-3 h-3 inline-block'}
                        style={{ background: entry.color }}
                    />
                    <span className="truncate capitalize">
                        {entry.name}{' '}
                        <span className="text-muted-foreground text-[10px]">
                            [{formatNumberToReadable(entry.value)}]
                        </span>
                    </span>
                </li>
            ))}
        </ul>
    )
}
