import { OrgTicketsTable } from '@repo/types/db'
import { Link, useParams } from '@tanstack/react-router'
import { ArrowUpRight} from 'lucide-react'

const DashboardTicketAnalyze = ({ data , totalTickets }: { data: number , totalTickets : Record<OrgTicketsTable['status'], number>}) => {
    const { orgId } = useParams({ from: '/_dashboard/org/$orgId' })

    return (
        <div className="space-y-4">
            <div className="flex justify-between items-center">
                <h3 className="text-lg">Ticket Analysis</h3>

                <Link
                    className="text-sm flex items-center gap-1 hover:text-primary"
                    to={`/org/$orgId/tickets`}
                    search={{ page: 0, sort: '' }}
                    params={{ orgId }}
                >
                    <span>View</span>
                    <ArrowUpRight width={14} height={14} />
                </Link>
            </div>
                    <ul className='text-sm'>
                        <li className='border-b py-3'>
                            <div className='flex items-center justify-between'>
                                <p>Tickets Analyzed</p>
                                {data || 0}
                            </div>

                        </li>
                        <li className='border-b py-3 last:border-0'>
                            <div className='flex items-center justify-between'>
                                <p>Yet to Analyze</p>
                                {totalTickets?.open + totalTickets?.closed+totalTickets?.escalated-data|| 0}
                            </div>

                        </li>
                    </ul>
            </div>
        // </div>
    )
}

export default DashboardTicketAnalyze
