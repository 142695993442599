import { User } from 'firebase/auth'
import { create } from 'zustand'

export type AuthStore = {
    user: User | null
    token: string | null
    loading: boolean
    update: (
        data: Partial<Pick<AuthStore, 'user' | 'token' | 'loading'>>
    ) => void
}

export const useAuthStore = create<AuthStore>((set) => ({
    user: null,
    token: null,
    loading: true,
    update: (data) => set(data),
}))
