import { inviteQueryOptions } from '@/queries/invite'
import { createFileRoute, Link } from '@tanstack/react-router'
import { buttonVariants } from '@ui/components/ui/button'
import { cn } from '@ui/lib/utils'

import ErrorPage from '@/components/custom/errorPage'
import { FullPageLoader } from '@/components/custom/loader'

export const Route = createFileRoute('/invitation/$id/')({
    loader: (opts) => {
        return opts.context.queryClient?.ensureQueryData(
            inviteQueryOptions(opts.params.id)
        )
    },
    pendingComponent: () => {
        return <FullPageLoader />
    },
    errorComponent: (err) => {
        return (
            <ErrorPage message={(err.error as any)?.message}>
                <Link to={'/orgs'} className={cn(buttonVariants())}>
                    <span>View All Organizations</span>
                </Link>
            </ErrorPage>
        )
    },
})
