import {
    SocketClientBusinessListeners,
    SocketServerBusinessEvents,
} from '@repo/types/socket'

import { ticketListeners } from './ticket'

export const handleListeners = <T extends keyof SocketServerBusinessEvents>(
    msg: SocketClientBusinessListeners<T>
) => {
    switch (msg.action) {
        case 'org:action':
            return true
        case 'ticket:message':
            return ticketListeners.message(msg as any)

        case 'ticket:update': {
            return ticketListeners.update(msg as any)
        }

        default:
            return console.error(`Invalid event - ${msg.action}`)
    }
}
