import { useLayoutEffect } from 'react'
import { createFileRoute, useNavigate } from '@tanstack/react-router'

export const Route = createFileRoute('/_dashboard/org/$orgId/knowledge-base/')({
    // eslint-disable-next-line no-use-before-define
    component: Component,
})

function Component() {
    const { orgId } = Route.useParams()
    const navigate = useNavigate()
    useLayoutEffect(() => {
        navigate({
            to: '/org/$orgId/knowledge-base/intents',
            params: { orgId },
            replace: true,
        })
    }, [])
    return null
}
