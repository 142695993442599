import { ReactElement } from 'react'
import { singleOrgQueryOptions } from '@/queries/orgs'
import { useGlobalStore } from '@/store/global'
import { cn } from '@repo/ui/lib/utils'
import { useQuery } from '@tanstack/react-query'
import { Link } from '@tanstack/react-router'
import {
    AreaChart,
    Blocks,
    BookOpen,
    Bot,
    Home,
    Store,
    Ticket,
    User,
} from 'lucide-react'

import { buttonVariants } from '@repo/ui/components/ui/button'
import { ScrollArea } from '@repo/ui/components/ui/scroll-area'
import { Separator } from '@repo/ui/components/ui/separator'
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from '@repo/ui/components/ui/tooltip'

import OrgSwitcher from '../custom/orgSwitcher'
import { HelpCard } from '../custom/helpCard'

export const DashboardNav = () => {
    const { org: orgId } = useGlobalStore()

    const { data: org } = useQuery(
        singleOrgQueryOptions({ orgId: orgId || '' })
    )

    const ticketNav = {
        title: 'Support Tickets',
        icon: <Ticket width={20} height={20} strokeWidth={1.5} />,
        url: '/org/$orgId/tickets',
        params: { orgId },
    }
    const homeNav = {
        title: 'Home',
        icon: <Home width={18} height={18} strokeWidth={1.5} />,
        url: `/org/$orgId`,
        params: { orgId },
        exact: true,
    }

    const DefaultNavs = [
        {
            title: 'Organization',
            icon: <Store width={20} height={20} strokeWidth={1.5} />,
            url: `/org/$orgId/settings`,
            params: { orgId },
        },
        {
            title: 'Account',
            icon: <User width={20} height={20} strokeWidth={1.5} />,
            url: '/account',
        },
        // {
        //     title: 'Help',
        //     icon: <AlertCircle width={20} height={20} strokeWidth={1.5} />,
        //     url: '/help',
        // },
    ]

    const navLinks: Array<{
        title?: string
        links: Array<{
            title: string
            icon: ReactElement
            label?: string
            url: string
            exact?: boolean
            params?: any
        }>
    }> =
        org?.member_role === 'agent'
            ? [{ links: [homeNav, ticketNav] }, { links: DefaultNavs }]
            : [
                  {
                      links: [
                          homeNav,
                          ticketNav,
                          {
                              title: 'Knowledge Base',
                              icon: (
                                  <BookOpen
                                      width={18}
                                      height={18}
                                      strokeWidth={1.4}
                                  />
                              ),
                              url: '/org/$orgId/knowledge-base',
                              params: { orgId },
                          },

                          {
                              title: 'Assistant',
                              icon: (
                                  <Bot
                                      width={23}
                                      height={23}
                                      strokeWidth={1.2}
                                  />
                              ),
                              url: '/org/$orgId/assistant',
                              params: { orgId },
                          },
                          {
                              title: 'Integrations',
                              icon: (
                                  <Blocks
                                      width={22}
                                      height={22}
                                      strokeWidth={1.2}
                                  />
                              ),
                              url: '/org/$orgId/integrations',
                              params: { orgId },
                          },
                          {
                            title: 'Reports',
                              icon: (
                                  <AreaChart
                                      width={22}
                                      height={22}
                                      strokeWidth={1.2}
                                  />
                              ),
                              url: '/org/$orgId/reports',
                              params: { orgId },
                          }
                      ],
                      
                  },
                  {
                      links: DefaultNavs,
                  },
              ]

    return (
        <TooltipProvider delayDuration={0}>
            <nav className="flex flex-col h-screen w-[70px] border-r">
                <div
                    className={cn('flex h-[52px] items-center justify-center')}
                >
                    <OrgSwitcher />
                </div>
                <Separator />
                <ScrollArea>
                    <div className="min-h-[calc(100vh-53px)] flex flex-col justify-between">
                        {navLinks.map((nav, i) => (
                            <div
                                className="flex flex-col items-center justify-center border-t first:border-0 py-1.5"
                                key={`nav-${i}`}
                            >
                                {nav.links.map((link, key) => (
                                    <Tooltip key={key} delayDuration={0}>
                                        <TooltipTrigger asChild>
                                            <Link
                                                to={link.url}
                                                params={link.params}
                                                preload="intent"
                                                activeOptions={{
                                                    exact: link?.exact || false,
                                                }}
                                                activeProps={{
                                                    className: cn(
                                                        buttonVariants({
                                                            variant: 'default',
                                                            size: 'icon',
                                                        }),
                                                        'h-10 w-10 my-0.5'
                                                    ),
                                                }}
                                                className={cn(
                                                    buttonVariants({
                                                        variant: 'ghost',
                                                        size: 'icon',
                                                    }),
                                                    'h-10 w-10 my-0.5'
                                                )}
                                            >
                                                {link.icon && link.icon}
                                                <span className="sr-only">
                                                    {link.title}
                                                </span>
                                            </Link>
                                        </TooltipTrigger>
                                        <TooltipContent
                                            side="right"
                                            className="flex items-center gap-4"
                                        >
                                            <div>
                                                {nav.title && (
                                                    <p className="text-xs text-muted-foreground">
                                                        {nav.title}
                                                    </p>
                                                )}
                                                <p className="font-medium">
                                                    {link.title}
                                                </p>
                                            </div>
                                            
                                        </TooltipContent>
                                        
                                    </Tooltip>
                                    
                                ))}

                               {i===1 && <HelpCard trigger="IconButton" />}
                            </div>
                            
                        ))}
                        
                    </div>
                    {/* <HelpCard trigger="IconButton"/> */}
                </ScrollArea>
            </nav>
        </TooltipProvider>
    )
}
