import { Button, buttonVariants } from "@ui/components/ui/button"
import { Popover, PopoverContent, PopoverTrigger } from "@ui/components/ui/popover"
import { Separator } from "@ui/components/ui/separator"
import { AlertCircle, ArrowUpRightFromSquareIcon, BookOpen, Mail, YoutubeIcon} from "lucide-react"
import discord from '../../assets/discord.svg'
import { Label } from "@ui/components/ui/label"
import { cn } from "@ui/lib/utils"

export const HelpCard=({trigger}:{
    trigger : string | undefined
})=> {
  return (
    <Popover >
      <PopoverTrigger asChild>
        {trigger==='textButton'? (<Button variant={'ghost'} size="sm">
                    Help
                </Button>) : (<AlertCircle width={20} height={20} strokeWidth={1.5} className='w-1/2 mx-auto cursor-pointer'/>)}
           
      </PopoverTrigger>
      
      <PopoverContent className={cn(trigger === 'IconButton' ? 'ml-16 w-96' : 'ml-0 w-96')} align={trigger === 'textButton' ? 'end' : 'start'}>
     
        <p className='font-semibold text-lg'>How can we help you ?</p>
        <div className='flex py-2 space-x-4 items-center justify-between'>
        <YoutubeIcon className='w-16 h-16' strokeWidth={1}/>
        <div>
            <Label>Video Library</Label>
            <p className='text-muted-foreground text-xs'>Explore our you tube channel to learn more about features and developments.</p>
        </div>
        <a href='https://www.youtube.com/@lipyai' target='_blank' rel='noopener noreferrer'>
            <ArrowUpRightFromSquareIcon className='w-6 h-6 hover:text-muted-foreground'/>
            </a>
        </div>


        <div className='flex py-2 space-x-4 items-center justify-between'>
        <BookOpen className='w-16 h-16' strokeWidth={1}/>
        <div>
            <Label>Blogs</Label>
            <p className='text-muted-foreground text-xs'>Simplify your experience with our step-by-step how-to guides for common tasks and features.</p>
        </div>
        <a href='https://www.lipy.ai/guides' target='_blank' rel='noopener noreferrer'>
            <ArrowUpRightFromSquareIcon className='w-6 h-6 hover:text-muted-foreground'/>
            </a>
        </div>

            <div className='py-4'>
            <p className='text-muted-foreground'>Facing trouble while setting up your assistant ? </p>
            <a href='mailto:team@lipy.ai' target='_blank' rel='noopener noreferrer' className={cn(buttonVariants({variant:"outline"}) , 'mt-2 hover:ring-1 hover:ring-primary w-full text-base')}>
                <Mail className='w-5 h-5 text-muted-foreground'/>
                Contact Support
            </a>
            </div>


            <Separator/>
            <p className='font-semibold text-lg py-2'>Reach out to our community</p>
            <p className='text-muted-foreground'>For other support or suggestions , join our community and directly chat with the team </p>
            {/* <a href='https://discord.gg/UrPdPSeS' target='_blank' rel='noopener noreferrer'>
                <Icons.DiscordIcon className='w-24 h-24 border rounded-md bg-muted/40 my-4 p-4 hover:border-2'/>
            </a>
            <a href='https://chat.whatsapp.com/BQU66RvuYPfI4ZwCNMhGrD' target='_blank' rel='noopener noreferrer'>
                <Icons.WhatsAppIcon className='w-24 h-24 border rounded-md bg-muted/40 my-4 p-4 hover:border-2'/>
            </a>
            <a href='https://www.reddit.com/r/lipy/' target='_blank' rel='noopener noreferrer'>
                <Icons.RedditIcon className='w-24 h-24 border rounded-md bg-muted/40 my-4 p-4 hover:border-2'/>
            </a> */}

                    <a href='https://discord.gg/UrPdPSeS' target='_blank' rel='noopener noreferrer'>
                    <img src={discord} className='object-cover w-full h-28 rounded-md my-2 hover:border-2'/>
            </a>
      </PopoverContent>
    </Popover>
  )
}
