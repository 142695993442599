import { useParams } from '@tanstack/react-router'

import { Separator } from '@repo/ui/components/ui/separator'

import { Nav } from '../custom/sidebar'

const KBNav = () => {
    const { orgId } = useParams({ from: '/_dashboard/org/$orgId' })
    const links = [
        {
            links: [
                {
                    title: 'Intents',
                    url: `/org/${orgId}/knowledge-base/intents`,
                },
                {
                    title: 'Ticket Forms',
                    url: `/org/${orgId}/knowledge-base/forms`,
                },
                {
                    title: 'Custom Flows',
                    url: `/org/${orgId}/knowledge-base/flows`,
                },
            ],
        },
    ]

    return (
        <div className="w-[200px] border-r h-screen sticky top-0">
            <div className="sticky top-0 bg-background">
                <div className="flex justify-between px-3 py-2 items-center">
                    <div className="h-9 flex item-center">
                        <h1 className="font-semibold text-lg leading-none my-auto">
                            Knowledge Base
                        </h1>
                    </div>
                </div>
                <Separator />
            </div>
            <div className="py-5 grid gap-2">
                {links.map((l, i) => (
                    <Nav key={i} name={''} links={l.links} />
                ))}
            </div>
        </div>
    )
}

export default KBNav
