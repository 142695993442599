import * as React from 'react'
import { allOrgQueryOptions, ALLOrgsResult } from '@/queries/orgs'
import { useGlobalStore } from '@/store/global'
import { OrgListTable } from '@repo/types/db'
import { cn } from '@repo/ui/lib/utils'
import { useSuspenseQuery } from '@tanstack/react-query'
import { useNavigate } from '@tanstack/react-router'
import { Check, PlusCircle } from 'lucide-react'

import {
    Avatar,
    AvatarFallback,
    AvatarImage,
} from '@repo/ui/components/ui/avatar'
import { Button } from '@repo/ui/components/ui/button'
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
    CommandSeparator,
} from '@repo/ui/components/ui/command'
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from '@repo/ui/components/ui/popover'

import { CreateOrgDialog } from '../org/create'

type PopoverTriggerProps = React.ComponentPropsWithoutRef<typeof PopoverTrigger>

export default function OrgSwitcher({ className }: PopoverTriggerProps) {
    const [open, setOpen] = React.useState(false)
    const [showNewTeamDialog, setShowNewTeamDialog] = React.useState(false)
    const navigate = useNavigate()

    const { org: orgId } = useGlobalStore()

    const [currOrg, setCurrOrg] = React.useState<OrgListTable | null>(null)

    const { data } = useSuspenseQuery(allOrgQueryOptions())

    React.useEffect(() => {
        if (!orgId || !data) return

        for (const key of Object.keys(data)) {
            const curr = data[key as keyof ALLOrgsResult]?.find(
                (o: OrgListTable) => o.id === orgId
            )
            if (curr) {
                setCurrOrg(curr)
                break
            }
        }
    }, [orgId, data])

    return (
        <React.Fragment>
            <Popover open={open} onOpenChange={setOpen}>
                <PopoverTrigger asChild>
                    <Button
                        variant="outline"
                        role="combobox"
                        aria-expanded={open}
                        aria-label="Select a team"
                        className={cn(
                            'w-full border-none mx-2 p-0 justify-center',
                            className
                        )}
                    >
                        <Avatar className={'h-8 w-8 border'}>
                            <AvatarImage
                                src={currOrg?.picture}
                                alt={currOrg?.name}
                            />
                            <AvatarFallback>{currOrg?.name[0]}</AvatarFallback>
                        </Avatar>
                    </Button>
                </PopoverTrigger>
                <PopoverContent className="ml-2 w-full p-0">
                    <Command>
                        <CommandList>
                            <CommandInput placeholder="Search team..." />
                            <CommandEmpty>No team found.</CommandEmpty>
                            {(
                                ['owned', 'delegated'] as Array<
                                    keyof ALLOrgsResult
                                >
                            ).map((key) => (
                                <CommandGroup
                                    key={key}
                                    heading={data[key].length > 0 && key}
                                >
                                    {data &&
                                        data[key].map((team, k) => (
                                            <CommandItem
                                                key={`team-${k}`}
                                                onSelect={() => {
                                                    setOpen(false)
                                                    navigate({
                                                        to: '/org/$orgId',
                                                        params: {
                                                            orgId: team.id,
                                                        },
                                                    })
                                                }}
                                                className="text-sm"
                                            >
                                                <Avatar className="mr-2 h-5 w-5">
                                                    <AvatarImage
                                                        src={team.picture}
                                                        alt={team.name}
                                                        className="grayscale"
                                                    />
                                                    <AvatarFallback className="p-2 text-xs">
                                                        {team.name[0]}
                                                    </AvatarFallback>
                                                </Avatar>
                                                {team.name}
                                                <Check
                                                    className={cn(
                                                        'ml-auto h-4 w-4',
                                                        currOrg?.id === team.id
                                                            ? 'opacity-100'
                                                            : 'opacity-0'
                                                    )}
                                                />
                                            </CommandItem>
                                        ))}
                                </CommandGroup>
                            ))}
                        </CommandList>
                        <CommandSeparator />
                        <CommandList>
                            <CommandGroup>
                                <CommandItem
                                    onSelect={() => {
                                        setOpen(false)
                                        setShowNewTeamDialog(true)
                                    }}
                                >
                                    <PlusCircle className="mr-2 h-5 w-5" />
                                    Create Organization
                                </CommandItem>
                            </CommandGroup>
                        </CommandList>
                    </Command>
                </PopoverContent>
            </Popover>
            <CreateOrgDialog
                open={showNewTeamDialog}
                onOpenChange={setShowNewTeamDialog}
            />
        </React.Fragment>
    )
}
