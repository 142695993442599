import { queryOptions } from '@tanstack/react-query'

import { apiQuery } from '@/lib/api'

export const orgDashboardQueryOptions = ({
    orgId,
    startDate,
    endDate,
}: {
    orgId: string
    startDate?: string
    endDate?: string
}) => {
    const searchParams = new URLSearchParams({
        startDate: startDate || '',
        endDate: endDate || '',
    })
    return queryOptions<any, any>({
        queryKey: ['dashboard', { orgId }],
        queryFn: () =>
            apiQuery({
                url: `/org/${orgId}/dashboard?${searchParams}`,
                method: 'GET',
            }),
    })
}
