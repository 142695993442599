import { createFileRoute } from '@tanstack/react-router'
import { z } from 'zod'

import ErrorPage from '@/components/custom/errorPage'

export const Route = createFileRoute('/_dashboard/org/$orgId/tickets/')({
    // loader: (opts) => {
    //     return opts.context.queryClient?.ensureQueryData(
    //         allTicketsQueryOptions({
    //             orgId: opts.params.orgId,
    //             page: 1,
    //             status: 'open,closed,escalated',
    //             sort: 'created_at:desc',
    //         })
    //     )
    // },
    // pendingComponent: () => {
    //     return <FullPageLoader />
    // },
    validateSearch: (search) =>
        z
            .object({
                q: z.string().optional(),
                page: z.number().int().min(0).catch(0),
                sort: z.string().optional(),
            })
            .parse(search),
    errorComponent: (err) => {
        return <ErrorPage message={(err.error as any).message}></ErrorPage>
    },
})
