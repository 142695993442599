import {format} from 'date-fns/format'
import { AreaChart as AChart, Area, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts"
import { CustomizedTooltip } from './tooltip'
export const AreaChart =({
    color,
    data,
}:{ color:string
    data: Array<{name:string; count:number}>
})=>{

    return(
        <ResponsiveContainer width={'100%'} height={160}>
            <AChart
                data={data}
                margin={{ right: 7, top: 5, left: 7, bottom: 5 }}
                >
                <defs>
                <linearGradient id="gradientColor" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor={color} stopOpacity={0.8} />
                    <stop offset="95%" stopColor={color} stopOpacity={0.1} />
                </linearGradient>
                </defs>
                <XAxis
                    dataKey="name"
                    stroke='hsl(var(--muted-foreground))'
                    fontSize={12}
                    tickLine={false}
                    axisLine={false}
                    minTickGap={32}
                    opacity={0.8}
                    tickFormatter={(value) =>
                        value && format(new Date(value), 'd MMMM')
                    }
                />
                <YAxis domain={[0,Math.max(...data.map(item => item.count)) + 1 ]} hide={true}/>
                <Area 
                    type='monotone' 
                    dataKey='count' 
                    fillOpacity={1}
                    strokeOpacity={0.5}
                    fill="url(#gradientColor)"
                    stroke={color}
                />
                <Tooltip content={<CustomizedTooltip/>}/>
                {/* <CartesianGrid vertical={false} /> */}
                <CartesianGrid 
                    vertical={false}
                    horizontal={true}
                    opacity={0.1}
                    stroke='hsl(var(--muted-foreground))'
                />
                
                
            </AChart>

        </ResponsiveContainer>
    )
}