import configuration from '@/configuration'
import { useAuthStore } from '@/store/auth'
import { io } from 'socket.io-client'

export const socket = io(configuration.customer_api.socket, {
    transports: ['websocket'],
    path: '/ws/',
    autoConnect: false,
})

// Track user activity
let lastActivityTime = Date.now()

// Set idle timeout duration in milliseconds
const IDLE_TIMEOUT = 60000 * 5 // 5 minute

// Function to update last activity time
function updateActivity() {
    lastActivityTime = Date.now()
}

// Function to disconnect if idle
function checkIdleTimeout() {
    const currentTime = Date.now()
    const token = useAuthStore.getState().token
    if (!token) return

    if (currentTime - lastActivityTime > IDLE_TIMEOUT) {
        // Inform the server to disconnect
        socket.emit('disconnectIdle')
        socket.io.reconnection(false)
        return socket.disconnect()
    }

    if (currentTime - lastActivityTime < IDLE_TIMEOUT && socket.disconnected) {
        return socket.connect()
    }
}

// Update last activity on user interaction
document.addEventListener('mousemove', updateActivity)
document.addEventListener('keydown', updateActivity)

// Start the idle timeout check
setInterval(checkIdleTimeout, 5000) // Check every 30 seconds

// Listen for a response from the server to disconnect
socket.on('disconnect', () => {
    // clearInterval(idleCheckInterval) // Stop the idle check
    socket.disconnect() // Disconnect from the server
})
