import { createFileRoute } from '@tanstack/react-router'
import { z } from 'zod'

import ErrorPage from '@/components/custom/errorPage'
import { FullPageLoader } from '@/components/custom/loader'

export const Route = createFileRoute('/checkout/')({
    pendingComponent: FullPageLoader,
    validateSearch: (search) =>
        z
            .object({
                org: z.string(),
            })
            .parse(search),

    errorComponent: (err) => {
        return <ErrorPage message={(err.error as any)?.message}></ErrorPage>
    },
})
