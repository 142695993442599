import { OrgMemberResult } from '@repo/types/member'
import { queryOptions } from '@tanstack/react-query'

import { apiQuery } from '@/lib/api'

export const memberQueryOptions = (orgId: string) => {
    return queryOptions<OrgMemberResult, any>({
        queryKey: ['members', orgId],
        queryFn: () =>
            apiQuery({
                url: `/org/${orgId}/members`,
                method: 'GET',
            }),
    })
}
