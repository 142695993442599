import { UserType } from '@repo/types/common'
import { OrgListTable } from '@repo/types/db'
import { queryOptions } from '@tanstack/react-query'

import { apiQuery } from '@/lib/api'

export type ALLOrgsResult = {
    owned: OrgListTable[]
    delegated: OrgListTable[]
}

export const ALLOrgsQueryId = 'orgs'

export const singleOrgQueryOptions = (opts: { orgId: string }) => {
    return queryOptions<OrgListTable & { member_role: UserType }, any>({
        queryKey: ['org', opts],
        queryFn: () =>
            apiQuery({
                url: `/org/${opts.orgId}`,
                method: 'GET',
            }),
        enabled: !!opts.orgId,
    })
}

export const getOrgSignedUrl = (
    org_id: string,
    type: 'org-icon' | 'assistant-icon' | 'webhcat-icon' | 'knowledge-base'
): Promise<{ url: string }> =>
    apiQuery(
        {
            url: `/org/${org_id}/upload/${type}`,
            method: 'GET',
        },
        { showToast: true, throwError: false }
    )

export const allOrgQueryOptions = () =>
    queryOptions<ALLOrgsResult, any>({
        queryKey: ['orgs'],
        queryFn: async () => apiQuery({ url: '/orgs', method: 'GET' }),
    })
