import { singleOrgQueryOptions } from '@/queries/orgs'
import { cn } from '@repo/ui/lib/utils'
import { createFileRoute, Link } from '@tanstack/react-router'

import { buttonVariants } from '@repo/ui/components/ui/button'
import ErrorPage from '@/components/custom/errorPage'
import { FullPageLoader } from '@/components/custom/loader'

export const Component = () => {
    return (
        <div className="p-2">
            <h3>Settings</h3>
        </div>
    )
}

export const Route = createFileRoute('/_dashboard/org/$orgId/settings/')({
    loader: (opts) => {
        return opts.context.queryClient?.ensureQueryData(
            singleOrgQueryOptions({ orgId: opts.params.orgId })
        )
    },
    pendingComponent: () => {
        return <FullPageLoader />
    },
    errorComponent: (err) => {
        return (
            <ErrorPage message={(err.error as any)?.message}>
                <Link to={'/orgs'} className={cn(buttonVariants())}>
                    <span>View All Organizations</span>
                </Link>
            </ErrorPage>
        )
    },

     
    component: Component,
})
