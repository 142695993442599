import { createFileRoute } from '@tanstack/react-router'

import { FullPageLoader } from '@/components/custom/loader'

const Component = () => {
    return <div className="p-2">Deactivated</div>
}

export const Route = createFileRoute('/deactivated')({
    pendingComponent: FullPageLoader,
     
    component: Component,
})
