import React, { Suspense } from 'react'
import { GlobalProvider } from '@/contexts/global'
import { SocketProvider } from '@/contexts/socket'
import { useGlobalStore } from '@/store/global'
import { QueryClient } from '@tanstack/react-query'
import { createRootRouteWithContext, Outlet } from '@tanstack/react-router'

import { Toaster } from '@repo/ui/components/ui/sonner'
import { Auth } from '@/components/auth'

const TanStackRouterDevtools =
    process.env.NODE_ENV === 'production'
        ? () => null
        : React.lazy(() =>
              import('@tanstack/router-devtools').then((res) => ({
                  default: res.TanStackRouterDevtools,
              }))
          )

const ReactQueryDevtools =
    process.env.NODE_ENV === 'production'
        ? () => null
        : React.lazy(() =>
              import('@tanstack/react-query-devtools').then((res) => ({
                  default: res.ReactQueryDevtools,
              }))
          )

export const Route = createRootRouteWithContext<{
    // auth: Auth
    queryClient: QueryClient
}>()({
    // eslint-disable-next-line no-use-before-define
    component: RootComponent,
})

function RootComponent() {
    const { theme } = useGlobalStore()
    return (
        <main className="flex flex-col min-h-screen">
            <GlobalProvider>
                <Auth>
                    <SocketProvider>
                        <Outlet />
                    </SocketProvider>
                </Auth>

                <Toaster position="top-right" richColors theme={theme} />

                <Suspense>
                    {!import.meta.env.PROD &&
                        import.meta.env.VITE_DEV_TOOLS === 'true' && (
                            <>
                                <ReactQueryDevtools initialIsOpen={false} />
                                <TanStackRouterDevtools position="bottom-left" />
                            </>
                        )}
                </Suspense>
            </GlobalProvider>
        </main>
    )
}
