import { assistantQueryOptions } from '@/queries/assistant'
import { UserListTable } from '@repo/types/db'
import { useQuery } from '@tanstack/react-query'
import { useParams } from '@tanstack/react-router'
import { Progress } from '@ui/components/ui/progress'
import { Separator } from '@ui/components/ui/separator'
import { Switch } from '@ui/components/ui/switch'
import { cn } from '@ui/lib/utils'
import { formatRelative } from 'date-fns'
// import { formatAmount } from '@/lib/intl'
import { useAssistantMutation } from '@/mutations/assistant'
import debounce from 'debounce'

const DashboardAssistant = ({ user , satisfaction }: { user: UserListTable , satisfaction : number}) => {
    const { orgId } = useParams({ from: '/_dashboard/org/$orgId' })
    const {updateAssistant} = useAssistantMutation()
    const { data: assistant, isLoading: assistantLoading } = useQuery(
        assistantQueryOptions(orgId)
    )

    const debouncedFromChange =
        debounce(async()=>{
            await updateAssistant.mutateAsync({
                org_id : assistant?.org_id,
                active : !assistant?.active
            })
        }, 1000)
    return (
        <div className="col-span-2 space-y-6">
            <div
                className={cn(
                    'rounded-md border overflow-hidden text-primary-foreground bg-primary p-3 space-y-2 drop-shadow-xl min-h-20'
                )}
            >
                {user && (
                    <>
                        {/* {user.balance > 0 ? (
                            <>
                                <div className="flex justify-between items-center text-sm">
                                    <div className="flex items-center justify-end gap-1">
                                        <h3 className="text-lg">Credits</h3>
                                    </div>
                                    <div className="text-md flex justify-end items-center gap-1">
                                        <span>
                                            {formatAmount(
                                                user.currency,
                                                user.balance
                                            )}
                                        </span>
                                    </div>
                                </div>
                                <div className="bg-background rounded-full">
                                    <Progress
                                        className="h-1.5 bg-primary/70"
                                        indicatorClassName="bg-background"
                                        value={30}
                                    />
                                </div>
                            </>
                        ) : (
                            <div>
                                <div className="flex items-center gap-1">
                                    <h3 className="text-lg">Usage Cost</h3>
                                </div>
                                <div className="text-2xl font-semibold flex items-center gap-1">
                                    <div className='flex items-center justify-between'>
                                        {formatAmount(
                                            user.currency,
                                            Math.abs(user.balance)
                                        )}
                                    </div>
                                </div>
                            </div>
                        )} */}
                        <div>
                        <div className="flex items-center gap-1">
                            <h3 className="text-lg">Total Satisfaction</h3>
                        </div>
                            <div className="flex items-center space-x-4">
                                <Progress
                                    value={satisfaction || 0}
                                    className="h-3 flex-1 border"
                                    indicatorClassName="bg-secondary" // Adjust this color if needed
                                />
                                <p className='font-semibold text-xl flex-2'>{Number(satisfaction).toFixed(2) || 0}%</p>
                            </div>
                    </div>

                    </>
                )}
            </div>
            <div
                className={cn(
                    'border rounded-md overflow-hidden p-3 bg-muted/40',
                    assistantLoading && 'animate-pulse'
                )}
            >
                <div
                    className={cn(
                        'space-y-4',
                        assistantLoading ? 'opacity-0' : 'opacity-100'
                    )}
                >
                    <>
                        <div className="flex justify-between items-center rounded-md">
                            <h3 className="text-lg">Assistant</h3>
                            <Switch checked={assistant?.active || false} onCheckedChange={debouncedFromChange}
                            />
                        </div>

                        <div className="rounded-md space-y-2">
                            <div className="flex justify-between items-center text-sm">
                                <span>Status</span>
                                <span>
                                    {assistant?.active ? 'Active' : 'Inactive'}
                                </span>
                            </div>
                            <Separator />
                            <div className="flex justify-between items-center text-sm">
                                <span>Trained</span>
                                <span>
                                    {(assistant?.trained_at &&
                                        formatRelative(
                                            assistant?.trained_at,
                                            new Date()
                                        )) ||
                                        '-'}
                                </span>
                            </div>
                        </div>
                    </>
                </div>
            </div>
        </div>
    )
}

export default DashboardAssistant
