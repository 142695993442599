import { AssistantIntentsGroupsTable } from '@repo/types/db'
import { queryOptions } from '@tanstack/react-query'

import { apiQuery } from '@/lib/api'

export type IntentGrpResult = {
    items: Array<AssistantIntentsGroupsTable & { count: number }>
    hasMore: boolean
}
export const intentGrpQueryOptions = ({
    orgId,
    page,
    type,
    q,
}: {
    orgId: string
    q?: string
    type: AssistantIntentsGroupsTable['type']
    page: number
}) => {
    const searchParams = new URLSearchParams({
        type,
        q: q || '',
        page: String(page),
    })

    return queryOptions<IntentGrpResult>({
        queryKey: ['intent-grps', { orgId, type, q, page }],
        queryFn: () =>
            apiQuery({
                url: `/org/${orgId}/intent-groups?${searchParams.toString()}`,
                method: 'GET',
            }),
    })
}
