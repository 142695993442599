import { StrictMode } from 'react'
import { createRouter, RouterProvider } from '@tanstack/react-router'
import ReactDOM from 'react-dom/client'

import { queryClient } from '@/lib/queryClient'

import '@repo/ui/global.css'

import ErrorPage from './components/custom/errorPage.tsx'
import NotFoundPage from './components/custom/notfound.tsx'
// Import the generated route tree
import { routeTree } from './routeTree.gen.ts'

// Create a new router instance
const router = createRouter({
    routeTree,
    defaultPreload: 'intent',
    defaultNotFoundComponent: NotFoundPage,
    defaultErrorComponent: () => (
        <div className="flex h-screen justify-center items-center">
            <ErrorPage />
        </div>
    ),
    context: {
        // user: undefined!, // We'll inject this when we render
        queryClient,
    },
})

// Register the router instance for type safety
declare module '@tanstack/react-router' {
    interface Register {
        router: typeof router
    }
}

// Render the app
const rootElement = document.getElementById('root')!
if (!rootElement.innerHTML) {
    const root = ReactDOM.createRoot(rootElement)
    root.render(
        <StrictMode>
            <RouterProvider router={router} />
        </StrictMode>
    )
}
