import { useState } from 'react'
import { useUserMutation } from '@/mutations/user'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button } from '@ui/components/ui/button'
import {
    Dialog,
    DialogContent,
    DialogTitle,
    DialogTrigger,
} from '@ui/components/ui/dialog'
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormMessage,
} from '@ui/components/ui/form'
import { TextArea } from '@ui/components/ui/textarea'
import { useForm } from 'react-hook-form'
import { z } from 'zod'

const formSchema = z.object({
    feedback: z
        .string()
        .min(5, {
            message: 'Feedback must contain at least 5 characters',
        })
        .max(5000, {
            message: 'Feedback should not exceed 5000 characters',
        }),
})

export const FeedbackCard = () => {
    const [isOpen, setIsOpen] = useState(false)
    const { sendFeedback } = useUserMutation()

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            feedback: '',
        },
    })

    const { isSubmitting, isValid } = form.formState
    const cb = () => {
        setIsOpen(false)
        form.reset()
    }

    const onSubmit = async (values: z.infer<typeof formSchema>) => {
        await sendFeedback.mutateAsync({ ...values, cb })
    }

    const handleToggle = () => {
        setIsOpen(!isOpen)
    }
    return (
        <Dialog open={isOpen} onOpenChange={setIsOpen}>
            <DialogTrigger asChild>
                <Button variant={'outline'} size="sm" onClick={handleToggle}>
                    Feedback
                </Button>
            </DialogTrigger>
            <DialogContent showClose={!isSubmitting}>
                <DialogTitle>Submit Feedback</DialogTitle>
                <Form {...form}>
                    <form
                        onSubmit={form.handleSubmit(onSubmit)}
                        className="space-y-4 "
                    >
                        <FormField
                            control={form.control}
                            name="feedback"
                            render={({ field }) => (
                                <FormItem>
                                    <FormControl>
                                        <TextArea
                                            className="w-full h-36"
                                            placeholder="Share your valuable feedback"
                                            shouldResize={false}
                                            {...field}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <div className="flex justify-between">
                            <Button
                                variant={'outline'}
                                type="button"
                                onClick={() => form.reset()}
                            >
                                Clear
                            </Button>
                            <Button
                                variant="default"
                                type="submit"
                                disabled={isSubmitting || !isValid}
                            >
                                Send Feedback
                            </Button>
                        </div>
                    </form>
                </Form>

                <p className="max-w-sm text-sm text-muted-foreground">
                    Have a Technical issue ? Reach out to us at{' '}
                    <a
                        href="mailto:team@lipy.ai"
                        className="underline hover:text-primary"
                    >
                        team@lipy.ai
                    </a>
                </p>
            </DialogContent>
        </Dialog>
    )
}
