import { userQueryOptions } from '@/queries/user'
import { createFileRoute } from '@tanstack/react-router'

import ErrorPage from '@/components/custom/errorPage'
import { FullPageLoader } from '@/components/custom/loader'

export const Route = createFileRoute('/_dashboard/account')({
    loader: (opts) => {
        return opts.context.queryClient?.ensureQueryData(userQueryOptions())
    },
    pendingComponent: () => {
        return <FullPageLoader />
    },
    errorComponent: (err) => {
        return <ErrorPage message={(err.error as any)?.message}></ErrorPage>
    },
})
