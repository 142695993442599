import { useGlobalStore } from '@/store/global'
import { cn } from '@repo/ui/lib/utils'
import { createFileRoute } from '@tanstack/react-router'

import { Label } from '@repo/ui/components/ui/label'
import { RadioGroup, RadioGroupItem } from '@repo/ui/components/ui/radio-group'

const UI = ({ theme = 'light' }: { theme: 'light' | 'dark' | 'system' }) => {
    const genColors = (dark: boolean) => ({
        background: dark ? '#343434' : '#eee',
        foreground: dark ? '#464646' : '#fff',
        accent: dark ? '#696969' : '#ddd',
    })

    const E1 = ({
        accent,
        foreground,
        background,
    }: {
        accent: string
        foreground: string
        background: string
    }) => {
        return (
            <div
                style={{ background }}
                className={cn('space-y-2 rounded-sm w-full')}
            >
                <div
                    style={{ background: foreground }}
                    className={'space-y-2 rounded-md p-2 shadow-sm'}
                >
                    <div
                        style={{ background: accent }}
                        className="h-2 w-[20%] rounded-lg"
                    />
                    <div
                        style={{ background: accent }}
                        className="h-2 w-[80%] rounded-lg"
                    />
                </div>
            </div>
        )
    }

    const E2 = ({
        accent,
        foreground,
        hide,
    }: {
        accent: string
        foreground: string
        hide?: boolean
    }) => {
        return (
            <div
                style={{ background: foreground }}
                className="flex items-center space-x-2 rounded-md p-2 shadow-sm"
            >
                {hide ? (
                    <div className="h-4" />
                ) : (
                    <div
                        style={{ background: accent }}
                        className="h-4 w-4 rounded-full"
                    />
                )}
                <div
                    style={{ background: accent }}
                    className="h-2 w-[90%] rounded-lg"
                />
            </div>
        )
    }

    const Light = () => {
        const colors = genColors(false)
        return (
            <div
                style={{ background: colors.background }}
                className="space-y-2 p-2 rounded-md"
            >
                <E1 {...colors} />
                {[...Array(2)].map((_item, k) => (
                    <E2 {...colors} key={k} />
                ))}
            </div>
        )
    }

    const Dark = () => {
        const colors = genColors(true)
        return (
            <div
                style={{ background: colors.background }}
                className="space-y-2 p-2 rounded-md"
            >
                <E1 {...colors} />
                {[...Array(2)].map((_item, k) => (
                    <E2 {...colors} key={k} />
                ))}
            </div>
        )
    }

    const System = () => {
        const Dcolors = genColors(true)
        const Lcolors = genColors(false)

        return (
            <div
                style={{ background: Lcolors.background }}
                className="space-y-2 p-2 rounded-md relative"
            >
                <E1 {...Lcolors} />
                <E2 {...Dcolors} />
                <E2 {...Dcolors} />
            </div>
        )
    }

    return (
        <div className="items-center rounded-md border-2 border-muted p-1 hover:border-accent">
            {theme === 'dark' ? (
                <Dark />
            ) : theme === 'system' ? (
                <System />
            ) : (
                <Light />
            )}
        </div>
    )
}

export const AppearanceForm = () => {
    const { theme, update } = useGlobalStore()
    return (
        <div className="space-y-8">
            <div className="space-y-1">
                <Label>Theme</Label>
                <p className="text-sm text-muted-foreground">
                    Select the theme for the dashboard.
                </p>
                <RadioGroup
                    onValueChange={(val) => update({ theme: val as any })}
                    defaultValue={theme}
                    className="grid max-w-xl grid-cols-3 gap-8 pt-2"
                >
                    <Label>
                        <div className="[&:has([data-state=checked])>div]:border-primary">
                            <RadioGroupItem
                                value="system"
                                className="sr-only"
                            />

                            <UI theme="system" />

                            <span className="block w-full p-2 text-center font-normal">
                                System
                            </span>
                        </div>
                    </Label>
                    <Label>
                        <div className="[&:has([data-state=checked])>div]:border-primary">
                            <RadioGroupItem value="light" className="sr-only" />
                            <UI theme="light" />

                            <span className="block w-full p-2 text-center font-normal">
                                Light
                            </span>
                        </div>
                    </Label>
                    <Label>
                        <div className="[&:has([data-state=checked])>div]:border-primary">
                            <RadioGroupItem value="dark" className="sr-only" />

                            <UI theme="dark" />
                            <span className="block w-full p-2 text-center font-normal">
                                Dark
                            </span>
                        </div>
                    </Label>
                </RadioGroup>
            </div>
        </div>
    )
}

function Component() {
    return (
        <>
            <div className="space-y-6 p-8 2xl:max-w-screen-xl max-w-screen-lg lg:border-r flex-1">
                <div>
                    <h3 className="text-lg font-medium">Appearance</h3>
                    <p className="text-sm text-muted-foreground">
                        Customize the appearance of the app. Automatically
                        switch between day and night themes.
                    </p>
                </div>
                <AppearanceForm />
            </div>
        </>
    )
}

export const Route = createFileRoute('/_dashboard/account/appearance')({
    component: Component,
})
