import { format } from 'date-fns/format'
import { getUnixTime } from 'date-fns/getUnixTime'

export const formatDateToReadable = (date: any, opts?: { time?: boolean }) => {
    if (!date) return

    return format(
        new Date(date),
        `dd MMM yyyy${(opts?.time && ', hh:mm a') || ''}'`
    )
}
export const currentUnixTime = () => getUnixTime(new Date()) * 1000
