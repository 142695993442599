import { AssistantListTable } from '@repo/types/db'
import { queryOptions } from '@tanstack/react-query'

import { apiQuery } from '@/lib/api'

export const assistantQueryOptions = (orgId: string) => {
    return queryOptions<AssistantListTable>({
        queryKey: ['assistant', orgId],
        queryFn: () =>
            apiQuery({
                url: `/org/${orgId}/assistant`,
                method: 'GET',
            }),
    })
}
