import { firebase_auth } from '@/firebase/config'
import { UserListTable, UserPayments } from '@repo/types/db'
import { queryOptions } from '@tanstack/react-query'

import { apiQuery } from '@/lib/api'

export const deactivateUser = async (deactivate: boolean) => {
    const data = await apiQuery({
        url: '/user/deactivate',
        method: 'PATCH',
        body: { deactivate },
    })
    if (data) {
        !deactivate && (await firebase_auth.currentUser?.getIdToken(true)) // on reactivation
    }
    return data
}

export const userQueryOptions = () =>
    queryOptions<UserListTable, any>({
        queryKey: ['local-user'],
        queryFn: () =>
            apiQuery({
                url: `/user`,
                method: 'GET',
            }),
    })

export const paymentQueryOptions = () =>
    queryOptions<UserPayments[], any>({
        queryKey: ['payments'],
        queryFn: () =>
            apiQuery({
                url: `/payments`,
                method: 'GET',
            }),
    })
