// Import the functions you need from the SDKs you need

import { getAnalytics } from 'firebase/analytics'
import { getApps, initializeApp } from 'firebase/app'
import {
    browserLocalPersistence,
    connectAuthEmulator,
    getAuth,
} from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions'
import { connectStorageEmulator, getStorage } from 'firebase/storage'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: import.meta.env.VITE_FIREBASE_APT_KEY,
    authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
    projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
    storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGE_SENDER_ID,
    appId: import.meta.env.VITE_FIREBASE_APP_ID,
    measurementId: import.meta.env.VITE_FIREBASE_MESUREMENT_ID,
}

// Initialize Firebase
export const firebase_app =
    getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0]

export const firebase_auth = getAuth()
export const firebase_db = getFirestore(firebase_app)
export const firebase_fn = getFunctions(firebase_app)
export const firebase_storage = getStorage(firebase_app)
export const firebase_analytics = getAnalytics(firebase_app)

if (import.meta.env.VITE_APP_ENV === 'staging') {
    firebase_auth.setPersistence(browserLocalPersistence)
}

if (
    import.meta.env.MODE === 'staging' ||
    import.meta.env.VITE_FIREBASE_EMULATORS === 'true'
) {
    // connectFirestoreEmulator(firebase_db, 'localhost', 8081)
    connectFunctionsEmulator(firebase_fn, '127.0.0.1', 5001)

    connectAuthEmulator(firebase_auth, 'http://127.0.0.1:9099')

    connectStorageEmulator(firebase_storage, '127.0.0.1', 9199)

    console.warn('Conneted Firebase emulators, Please remove in production')
}
