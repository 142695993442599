import { OrgListTable } from '@repo/types/db'
import { queryOptions } from '@tanstack/react-query'

import { apiQuery } from '@/lib/api'

export const inviteQueryOptions = (inviteId: string) => {
    return queryOptions<Pick<OrgListTable, 'name' | 'picture' | 'id'>, any>({
        queryKey: ['invitation', inviteId],
        queryFn: () =>
            apiQuery({
                url: `/org/member/invite/${inviteId}`,
                method: 'GET',
            }),
    })
}
