import { intentGrpQueryOptions } from '@/queries/intentGrps'
import { intentCountQueryOptions } from '@/queries/intents'
import { createFileRoute } from '@tanstack/react-router'
import ErrorPage from '@/components/custom/errorPage'
import { FullPageLoader } from '@/components/custom/loader'
// import { z } from 'zod'

export const Route = createFileRoute(
    '/_dashboard/org/$orgId/knowledge-base/intents/'
)({
    loader: (opts) => {
        opts.context.queryClient?.ensureQueryData(
            intentGrpQueryOptions({
                orgId: opts.params.orgId,
                type: 'text',
                page: 0,
            })
        )
        opts.context.queryClient?.ensureQueryData(
            intentCountQueryOptions({
                orgId: opts.params.orgId,
            })
        )
        return true
    },
    pendingComponent: () => {
        return <FullPageLoader />
    },
    errorComponent: (err) => {
        return <ErrorPage message={(err.error as any)?.message}></ErrorPage>
    },
    // validateSearch:(search)=>
    //     z.object({
    //         q:z.string().optional().catch('')
    //     }).parse(search),
})
