import { userQueryOptions } from '@/queries/user'
import { useAuthStore } from '@/store/auth'
import { UserListTable } from '@repo/types/db'
import { useMutation } from '@tanstack/react-query'
import { useNavigate } from '@tanstack/react-router'
import { updateProfile } from 'firebase/auth'
import { toast } from 'sonner'

import { apiQuery } from '@/lib/api'
import { queryClient } from '@/lib/queryClient'

export const useUserMutation = () => {
    const { user } = useAuthStore()
    const navigate = useNavigate()
    const updateUser = useMutation<
        Response,
        unknown,
        Partial<
            Pick<
                UserListTable,
                'address' | 'notifications' | 'name' | 'phone_number'
            >
        >
    >({
        mutationFn: async (data) => {
            if (!data || !user) return
            data?.name !== user.displayName &&
                (await updateProfile(user, {
                    displayName: data.name,
                }))
            return await apiQuery(
                { url: '/user', method: 'PATCH', body: data },
                { showToast: true }
            )
        },
        onSuccess: (_data, vars, _contexts) => {
            queryClient.setQueryData(userQueryOptions().queryKey, (prev) => {
                if (!prev) return
                return { ...prev, ...vars }
            })
        },
    })

    const deleteUser = useMutation<Response, unknown, { deactivate: boolean }>({
        mutationFn: (data) =>
            apiQuery(
                { url: '/user', method: 'DELETE', body: data },
                { showToast: true }
            ),
        onSuccess: () => {
            navigate({ to: '/logout' })
        },
    })

    const sendFeedback = useMutation<
        Response,
        unknown,
        { feedback: string; cb: () => void }
    >({
        mutationFn: (data) =>
            apiQuery(
                { url: '/feedback', method: 'POST', body: data },
                { showToast: true }
            ),
        onSuccess: (_, { cb }) => {
            cb()
            toast.success('Thanks for your feedback')
        },
    })

    return { updateUser, deleteUser, sendFeedback }
}
