import * as React from 'react'
import { cn } from '@ui/lib/utils'
import { cva, type VariantProps } from 'class-variance-authority'

const badgeVariants = cva(
    'inline-flex items-center p-0 rounded-full border capitalize font-normal transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2',
    {
        variants: {
            variant: {
                default: 'border-primary/50 bg-primary/30 ',
                ghost: 'border-transparent bg-transparent',
                secondary:
                    'bg-secondary text-secondary-foreground hover:bg-secondary/80',
                destructive:
                    'border-transparent bg-destructive/40 text-foreground',
                outline: 'text-foreground',
            },
            size: {
                default: 'px-2 py-0.5 text-xs',
                sm: 'px-2 py-0.5 text-[0.7em]',
            },
        },
        defaultVariants: {
            variant: 'default',
            size: 'default',
        },
    }
)

export interface BadgeProps
    extends React.HTMLAttributes<HTMLDivElement>,
        VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, size, ...props }: BadgeProps) {
    return (
        <span
            className={cn(
                badgeVariants({ variant, size, className }),
                'inline-block leading-none'
            )}
            {...props}
        />
    )
}

export { Badge, badgeVariants }
