import { useEffect } from 'react'
import { useGlobalStore } from '@/store/global'
import { cn } from '@repo/ui/lib/utils'
import { Link } from '@tanstack/react-router'
import { ArrowLeft } from 'lucide-react'

import { buttonVariants } from '@repo/ui/components/ui/button'

const NotFoundPage = () => {
    const { org } = useGlobalStore()
    useEffect(() => {}, [window.location.href])
    return (
        <>
            <div className="flex flex-col flex-1 items-center justify-center space-y-6">
                <h1>404</h1>
                <div className="text-center">
                    <h1 className="text-2xl font-semibold">Page not found</h1>
                    <p className="px-4 max-w-sm text-sm text-muted-foreground">
                        It seem's page doesn't exists. Contact us on{' '}
                        <a
                            href="mailto:team@lipy.ai"
                            className="underline hover:text-primary"
                        >
                            team@lipy.ai
                        </a>{' '}
                        for any questions.
                    </p>
                </div>
                <Link
                    to={org ? '/org/$orgId' : '/'}
                    params={(org && { orgId: org }) || {}}
                    className={cn(buttonVariants())}
                >
                    <ArrowLeft className="w-4 h-4" />
                    <span>Back To Dashboard</span>
                </Link>
            </div>
        </>
    )
}

export default NotFoundPage
