import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

export type GlobalStoreCache = {
    userId?: string
    theme: 'system' | 'dark' | 'light'
    org?: string
    ticket?: string
}

type Actions = {
    update: (data: Partial<GlobalStoreCache>) => void
    reset: (userId: string) => void
}

export const useGlobalStore = create(
    persist<GlobalStoreCache & Actions>(
        (set) => ({
            theme: 'system',
            update: (data) => set(data),
            reset: (userId) =>
                set({ userId, org: undefined, ticket: undefined }),
        }),
        {
            name: 'lipy-global-store',
            storage: createJSONStorage(() => localStorage),
        }
    )
)
