'use client'

import { useState } from 'react'
import { logOut } from '@/firebase/auth'
import { useAuthStore } from '@/store/auth'
import { Link, useNavigate } from '@tanstack/react-router'

import {
    Avatar,
    AvatarFallback,
    AvatarImage,
} from '@repo/ui/components/ui/avatar'
import { Button } from '@repo/ui/components/ui/button'
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from '@repo/ui/components/ui/dropdown-menu'

export const UserNav = () => {
    const [loggingOut, setLoggingOut] = useState(false)
    const { user } = useAuthStore()
    const navigate = useNavigate()

    if (!user) return null

    const handleLogout = async (e: any) => {
        e.preventDefault()
        setLoggingOut(true)
        await logOut()
        navigate({ to: '/auth' })
    }

    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Button
                    variant="ghost"
                    className="relative h-9 w-9 rounded-full p-0"
                >
                    <Avatar className="h-8 w-8 border">
                        <AvatarImage
                            src={user.photoURL!}
                            alt={user.displayName!}
                            className="object-cover"
                        />
                        <AvatarFallback>
                            {user.displayName
                                ?.split(' ')
                                .map((chunk: any) => chunk[0])
                                .join('')
                                .toUpperCase()}
                        </AvatarFallback>
                    </Avatar>
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-56" align="end" forceMount>
                <DropdownMenuLabel className="font-normal">
                    <div className="flex flex-col space-y-1">
                        <p className="text-sm font-medium leading-none">
                            {user.displayName}
                        </p>
                        <p className="text-xs leading-none text-muted-foreground">
                            {user.email}
                        </p>
                    </div>
                </DropdownMenuLabel>
                <DropdownMenuSeparator />
                <DropdownMenuGroup>
                    <DropdownMenuItem asChild>
                        <Link to={'/account'}>Profile</Link>
                    </DropdownMenuItem>
                    <DropdownMenuItem asChild>
                        <Link to={'/account/billing'}>Billing</Link>
                    </DropdownMenuItem>
                </DropdownMenuGroup>
                <DropdownMenuSeparator />
                <DropdownMenuItem
                    onClick={handleLogout}
                    disabled={loggingOut}
                    className="space-x-2"
                >
                    <span>{loggingOut ? 'Logging out...' : 'Log out'} </span>
                </DropdownMenuItem>
            </DropdownMenuContent>
        </DropdownMenu>
    )
}
