
import { format } from 'date-fns'
import { AreaChart } from '../charts/areaChart'
const MessageChart = ({
    data,
}: {
    data?: Array<{ date: string; count: number }>
}) => {

    const chartData = (
        data: Array<{ date: string; count: number }> | undefined,
    ) => {
        if (!data) return []

        return data.map(item => {
            const dateStr = format(item.date, 'yyyy-MM-dd')
            const count = item.count

            return {
                name: dateStr,
                count,
            }
        })
    }

    return (
        <div>

            {chartData && (
                <AreaChart
                    color={'hsl(var(--primary))'}
                    data={chartData(data)}
                />
            )}
        </div>
    )
}

export default MessageChart
