import { useGlobalStore } from '@/store/global'
import { createFileRoute, Navigate } from '@tanstack/react-router'

import { FullPageLoader } from '@/components/custom/loader'

export const Component = function Index() {
    const { org } = useGlobalStore()
    return (
        <Navigate
            to={org ? '/org/$orgId' : '/orgs'}
            params={{ orgId: org }}
            replace
        />
    )
}

export const Route = createFileRoute('/')({
    pendingComponent: FullPageLoader,

    component: Component,
})
