import { useMutation } from '@tanstack/react-query'

//TODO: improve mutations - structure it
import { apiQuery } from '@/lib/api'

export const useShopifyMutation = () => {
    const deleteScripts = useMutation<
        { deletedScripts: Array<any> },
        unknown,
        { orgId: string }
    >({
        mutationFn: async (data) => {
            return await apiQuery(
                { url: `/shopify/script/delete/${data.orgId}`, method: 'POST' },
                { showToast: true }
            )
        },
    })

    const injectScript = useMutation<any, unknown, { orgId: string }>({
        mutationFn: async (data) => {
            return await apiQuery(
                { url: `/shopify/script/inject/${data.orgId}`, method: 'POST' },
                { showToast: true }
            )
        },
    })

    return { deleteScripts, injectScript }
}
