import { UsageType } from '@repo/types/common'
import { UserListTable, UserUsage } from '@repo/types/db'
import { Link, useParams } from '@tanstack/react-router'
import { ArrowUpRight } from 'lucide-react'

import { formatAmount } from '@/lib/intl'

const DashboardUsage = ({
    data,
    user,
}: {
    data: Pick<UserUsage, 'type' | 'amount' | 'unit'>[]
    user: UserListTable
}) => {
    const { orgId } = useParams({ from: '/_dashboard/org/$orgId' })

    const descGenerator: Record<UsageType, string> = {
        'assistant:text': `Assistant sent a message`,
        'training:text': `Trained FAQ's`,
        'training:document': `Trained Document`,
    }

    return (
        <div className="space-y-4">
            <div className="flex justify-between items-center">
                <h3 className="text-lg">Usage Activity</h3>

                <Link
                    className="text-sm flex items-center gap-1 hover:text-primary"
                    to={`/org/$orgId/settings/usage`}
                    params={{ orgId }}
                >
                    <span>View</span>
                    <ArrowUpRight width={14} height={14} />
                </Link>
            </div>
            <div className="">
                {data &&
                    data.map((item, i) => (
                        <div
                            key={i}
                            className="flex items-center justify-between py-3 border-b last:border-0 text-sm"
                        >
                            <div>
                                <p>{descGenerator[item.type]}</p>
                            </div>
                            <div>
                                <p>
                                    {formatAmount(user.currency, item.amount)}
                                </p>
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    )
}

export default DashboardUsage
