import {
    allOrgQueryOptions,
    ALLOrgsResult,
    singleOrgQueryOptions,
} from '@/queries/orgs'
import { OrgFormValues } from '@/validations/orgs'
import { OrgListTable } from '@repo/types/db'
import { useMutation } from '@tanstack/react-query'
import { useNavigate } from '@tanstack/react-router'
import { toast } from 'sonner'

import { apiQuery } from '@/lib/api'
import { queryClient } from '@/lib/queryClient'

export const useOrgMutation = () => {
    const navigate = useNavigate()
    const createOrg = useMutation<
        OrgListTable,
        unknown,
        Partial<OrgFormValues & { orgId: string }>
    >({
        mutationFn: async (data) => {
            return await apiQuery(
                { url: '/org', method: 'POST', body: data },
                { showToast: true }
            )
        },
        onSuccess: (data, _vars, _contexts) => {
            queryClient.setQueryData<ALLOrgsResult | undefined>(
                allOrgQueryOptions().queryKey,

                (prev) => {
                    if (!prev) return
                    const owned = [data]
                    if (prev?.owned) {
                        owned.push(...prev.owned)
                    }
                    return { ...prev, owned }
                }
            )

            queryClient.setQueryData(
                singleOrgQueryOptions({ orgId: data.id! }).queryKey,
                data as any
            )

            navigate({ to: '/org/$orgId', params: { orgId: data.id } })
        },
    })

    const updateOrg = useMutation<
        Response,
        unknown,
        Partial<OrgFormValues & { orgId: string }>
    >({
        mutationFn: async (data) => {
            return await apiQuery(
                { url: '/org/' + data.orgId, method: 'PATCH', body: data },
                { showToast: true }
            )
        },
        onSuccess: (data, vars, _contexts) => {
            queryClient.setQueryData(
                singleOrgQueryOptions({ orgId: vars.orgId! }).queryKey,
                (prev) => {
                    if (!prev) return
                    delete vars.orgId
                    return { ...prev, ...data }
                }
            )
        },
    })

    const deleteOrg = useMutation<Response, unknown, { orgId: string }>({
        mutationFn: (data) =>
            apiQuery(
                { url: '/org/' + data.orgId, method: 'DELETE', body: data },
                { showToast: true }
            ),
        onSuccess: () => {
            navigate({ to: '/orgs' })
            toast.success('Successfully deleted your organization')
        },
    })

    return { updateOrg, deleteOrg, createOrg }
}
