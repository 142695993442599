'use client'

import { useParams } from '@tanstack/react-router'

import { Separator } from '@repo/ui/components/ui/separator'

import { Nav } from '../custom/sidebar'

const OrgSettingNav = () => {
    const { orgId } = useParams({ from: '/_dashboard/org/$orgId' })
    const links = [
        {
            title: 'General',
            url: `/org/${orgId}/settings`,
        },
        {
            title: 'Members',
            url: `/org/${orgId}/settings/members`,
        },
        {
            title: 'Usage',
            url: `/org/${orgId}/settings/usage`,
        },
    ]

    return (
        <div className="w-[200px] border-r h-screen sticky top-0">
            <div className="sticky top-0 bg-background">
                <div className="flex justify-between px-3 py-2 items-center">
                    <div className="h-9 flex item-center">
                        <h1 className="font-semibold text-lg leading-none my-auto">
                            Organization
                        </h1>
                    </div>
                </div>
                <Separator />
            </div>
            <div className="py-5">
                <Nav links={links} />
            </div>
        </div>
    )
}

export default OrgSettingNav
