import { orgDashboardQueryOptions } from '@/queries/dashboard'
import { userQueryOptions } from '@/queries/user'
import { useQuery} from '@tanstack/react-query'
import { createFileRoute } from '@tanstack/react-router'
import { LoadingBlocks } from '@ui/components/custom/loader'

import ErrorPage from '@/components/custom/errorPage'
import { FullPageLoader } from '@/components/custom/loader'
import DashboardAssistant from '@/components/dashboard/assistant'
import DashboardKB from '@/components/dashboard/kb'
import MessageChart from '@/components/dashboard/messageChart'
import TicketChart from '@/components/dashboard/ticketChart'
import DashboardTickets from '@/components/dashboard/tickets'
import DashboardUsage from '@/components/dashboard/usage'
import { intentCountQueryOptions } from '@/queries/intents'
import DashboardTicketAnalyze from '@/components/dashboard/ticketAnalyze'
import { DateRangePicker } from '@ui/components/custom/date-range-picker'
import { useEffect, useState } from 'react'
import { subDays } from 'date-fns'

type DateRange = {
    from: Date | undefined
    to?: Date | undefined
}

export const Route = createFileRoute('/_dashboard/org/$orgId/')({
    pendingComponent: FullPageLoader,
    // eslint-disable-next-line no-use-before-define
    component: Component,
})

function Component() {
    const { orgId } = Route.useParams()
    const [selectedDate, setSelectedDate] = useState<DateRange | undefined>({
        from: subDays(new Date(), 30),
        to: new Date(),
    })

    const startDate = selectedDate?.from?.toISOString()
    const endDate = selectedDate?.to?.toISOString()

    const {data,isLoading,refetch} = useQuery(orgDashboardQueryOptions({orgId,startDate,endDate}))

    useEffect(()=>{
        refetch()
    },[selectedDate,refetch])

    const { data: user, isLoading: userLoading } = useQuery(userQueryOptions())
    const {data : intent} = useQuery(intentCountQueryOptions({orgId}))
    if (userLoading || isLoading) {
        return (
            <LoadingBlocks description="Loading your organization dashboard" />
        )
    }

    if (!user) {
        return <ErrorPage message="Failed to load user!" />
    }
    return (
        <div className="p-8 space-y-10 flex flex-col flex-1">
            <div className="grid grid-cols-9 auto-rows-min gap-6 h-fit">
                <div className="row-span-1 col-span-4 border rounded-md pt-2 bg-muted/40 px-4">
                <div className='flex justify-between py-2'>
                <h3 className="text-lg">Messages</h3>
                <DateRangePicker 
                    date={selectedDate} 
                    setDate={setSelectedDate}
                    className='w-[240px] h-8'
                />
            </div>
                <MessageChart data={data?.messageCount} />
                
                </div>
                
                <TicketChart data={data?.tickets} />
                <DashboardAssistant user={user} satisfaction={data?.analyzedTicket[0]?.average_satisfaction} />
            </div>
            <div className="grid grid-cols-9 gap-6 flex-1">
                <div className="row-span-2 col-span-5 border rounded-md p-4 bg-muted/40">
                    <DashboardTickets data={data?.recentTickets} />
                </div>
                <div className="row-span-2 col-span-2 border rounded-md p-4 bg-muted/40">
                    <DashboardUsage data={data?.usage} user={user} />
                </div>
                <div className="row-span-1 col-span-2 border rounded-md p-4 bg-muted/40">
                    <DashboardKB data={intent}/>
                    
                </div>
                <div className="row-span-1 col-span-2 border rounded-md p-4 bg-muted/40">
                    <DashboardTicketAnalyze data={data?.analyzedTicket[0]?.ticket_count} totalTickets={data?.tickets}/>
                    
                </div>
            </div>
        </div>
    )
}
 