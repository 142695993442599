import { ReactNode, useEffect } from 'react'
import { GlobalStoreCache, useGlobalStore } from '@/store/global'
import { QueryClientProvider } from '@tanstack/react-query'

import { queryClient } from '@/lib/queryClient'

const theme = ['system', 'light', 'dark']
const handleTheme = (colorScheme: GlobalStoreCache['theme']) => {
    const remove = theme.filter((l) => l !== String(colorScheme))
    document?.documentElement?.classList.add(colorScheme)
    remove.forEach((r) => document?.documentElement?.classList.remove(r))
}

export const GlobalProvider = ({ children }: { children: ReactNode }) => {
    const { theme } = useGlobalStore()
    useEffect(() => {
        if (!theme) return

        const media = window.matchMedia('(prefers-color-scheme: dark)')

        const handler = (event: MediaQueryListEvent | MediaQueryList) => {
            if (theme !== 'system') return handleTheme(theme)
            const colorScheme = event.matches ? 'dark' : 'light'
            handleTheme(colorScheme)
        }

        if (theme === 'system') {
            media.addEventListener('change', handler)
        }
        handler(media)

        return () => media.removeEventListener('change', handler)
    }, [theme])

    return (
        <QueryClientProvider client={queryClient}>
            {children}
        </QueryClientProvider>
    )
}
