import { IntentCountResult } from '@/queries/intents'
// import { KnowledgeBaseType } from '@repo/types/kb'
import { Link, useParams } from '@tanstack/react-router'
import { ArrowUpRight} from 'lucide-react'

const DashboardKB = ({ data }: { data: IntentCountResult | undefined }) => {
    const { orgId } = useParams({ from: '/_dashboard/org/$orgId' })

    // const names: Record<KnowledgeBaseType, string> = {
    //     intent: 'Intents',
    //     form: 'Forms',
    //     flow: 'Custom Flows',
    // }
    return (
        <div className="space-y-4">
            <div className="flex justify-between items-center">
                <h3 className="text-lg">Knowledge Base</h3>

                <Link
                    className="text-sm flex items-center gap-1 hover:text-primary"
                    to={`/org/$orgId/knowledge-base/intents`}
                    params={{ orgId }}
                >
                    <span>View</span>
                    <ArrowUpRight width={14} height={14} />
                </Link>
            </div>
            {/* <div className=""> */}
                {/* {data &&
                    Object.keys(data).map((item, i) => (
                        <div
                            key={i}
                            className="flex items-center justify-between py-3 border-b last:border-0 text-sm"
                        >
                            <div>
                                <p>{(names as any)[item]}</p>
                            </div>
                            <div>
                                <p className="font-semibold">
                                    {(data as any)[item]}
                                </p>
                            </div>
                        </div>
                    ))} */}
                    <ul className='text-sm'>
                        <li className='border-b py-3'>
                            <div className='flex items-center justify-between'>
                                <p>Intents</p>
                                {data? (<p>{data?.active + data?.disabled}</p>) : (<p>{0}</p>)}
                            </div>
                            <div className='relative'>
                                <div className='absolute left-0 top-0 bottom-0 border-l border-muted-foreground/40' style={{ height: '84%' }}></div>
                            
                                <div className='flex items-center justify-between py-3'>
                                    <div className='flex space-x-2 items-center'>
                                        <p className='border-t border-muted-foreground/40 w-4'></p>
                                        <p>Active</p>
                                    </div>
                                    <p>{data?.active || 0}</p>
                                </div>
                                <div className='flex items-center justify-between'>
                                    <div className='flex space-x-2 items-center'>
                                        <p className='border-t border-muted-foreground/40 w-4'></p>
                                        <p>Disabled</p>
                                    </div>
                                    <p>{data?.disabled || 0}</p>
                                </div>
                        </div>

                        </li>
                        <li className='flex items-center justify-between py-3 border-b'>
                            <p>Forms</p>
                            {0}
                        </li>
                        <li className='flex items-center justify-between py-3'>
                            <p>Flows</p>
                            {0}
                        </li>
                        
                    </ul>
            </div>
        // </div>
    )
}

export default DashboardKB
