'use client'

import { Separator } from '@repo/ui/components/ui/separator'

import { Nav } from '../custom/sidebar'

const AccountNav = () => {
    const links = [
        {
            title: 'Profile',
            url: '/account',
        },
        {
            title: 'Billing',
            url: '/account/billing',
        },
        {
            title: 'Notifications',
            url: '/account/notifications',
        },
        {
            title: 'Appearance',
            url: '/account/appearance',
        },
    ]

    return (
        <div className="w-[200px] border-r h-screen sticky top-0">
            <div className="sticky top-0 bg-background">
                <div className="flex justify-between px-3 py-2 items-center">
                    <div className="h-9 flex item-center">
                        <h1 className="font-semibold text-lg leading-none my-auto">
                            Account
                        </h1>
                    </div>
                </div>
                <Separator />
            </div>
            <div className="py-5">
                <Nav links={links} />
            </div>
        </div>
    )
}

export default AccountNav
