import { assistantQueryOptions } from '@/queries/assistant'
import { AssistantListTable } from '@repo/types/db'
import { useMutation } from '@tanstack/react-query'

import { apiQuery } from '@/lib/api'
import { queryClient } from '@/lib/queryClient'

export const useAssistantMutation = () => {
    const updateAssistant = useMutation<
        Response,
        unknown,
        Omit<Partial<AssistantListTable>, 'webchat'> & {
            webchat?: Partial<AssistantListTable['webchat']>
        }
    >({
        mutationFn: async ({ org_id, ...data }) => {
            return await apiQuery(
                {
                    url: `/org/${org_id}/assistant`,
                    method: 'PATCH',
                    body: data,
                },
                { showToast: true }
            )
        },
        onSuccess: (data, { org_id }, _contexts) => {
            queryClient.setQueryData(
                assistantQueryOptions(org_id!).queryKey,
                (prev) => ({ ...prev, ...(data as any) })
            )
        },
    })

    return { updateAssistant }
}
