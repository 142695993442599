const production =
    import.meta.env.MODE === 'production' ||
    import.meta.env.NODE_ENV === 'production'

const configuration = {
    production,

    packages: {
        webchat: production
            ? 'https://cdn.lipy.ai/packages/webchat.js'
            : 'http://localhost:3002/webchat.js',
    },
    customer_api: {
        url: import.meta.env.VITE_MONOLITHIC_URL,
        socket: import.meta.env.VITE_MONOLITHIC_URL + '/business',
    },

    facebook: {
        app_id: import.meta.env.VITE_FB_APP_ID,
        config_id: {
            business_management: 1704011510087091,
            full_access: 1420036632222158,
        },
    },
    site: {
        name: 'Lipy AI - Chat assistant for businesses to automate customer service.',
        description:
            'Lipy.ai is here to automate your customer conversations, providing personalized responses for customer inquiries, order taking, and efficient appointment bookings. Enhance your customer satisfaction rate and drive business growth.',
        themeColor: '#00BB78',
        siteUrl: import.meta.env.VITE_WEB_URL,
        siteName: 'Lipy AI Chat Assistant.',
        twitterHandle: '@LipyAi_',
        language: 'en',
    },
    socialLinks: {
        youtube: 'https://www.youtube.com/channel/UCg42GtJQbatJQo1piNUEcGw',
        instagram: 'https://www.instagram.com/lipy.ai/',
        facbook: 'https://www.facebook.com/people/Lipy-AI/61553677252793/',
        twitter: 'https://twitter.com/LipyAi_',
        linkedin: 'https://www.linkedin.com/company/lipy-ai/',
    },
    defaults: {
        assistant: {
            image: 'https://api.dicebear.com/7.x/fun-emoji/svg?eyes=glasses&mouth=cute&backgroundColor=ffdfbf',
        },
    },
    support: {
        email: 'team@lipy.ai',
    },

    plans: {
        monthly: [
            {
                id: 'starter_monthly',
                name: 'Starter',
                rzp: {
                    id: 'plan_OeIozIEgsOWMmB',
                    amount: 850,
                    currency: 'INR',
                },
                highlights: [
                    { label: '1000 AI Replies' },
                    { label: '200 AI Intents' },
                    {
                        label: '200 minutes of Live Chat / Audio / Video sessions',
                    },
                ],
            },
            {
                id: 'business_monthly',
                name: 'Business',
                rzp: {
                    id: 'plan_OeIpy7y0vSnby8',
                    amount: 2100,
                    currency: 'INR',
                },
                highlights: [
                    { label: '3000 AI Replies' },
                    { label: '1000 AI Intents' },
                    {
                        label: '1000 minutes of Live Chat / Audio / Video sessions',
                    },
                ],
            },
        ],
    },
}
// console.log(configuration)
export default configuration
