import { useMemo } from 'react'
import { singleOrgQueryOptions } from '@/queries/orgs'
import { useQuery } from '@tanstack/react-query'
import {
    createFileRoute,
    MatchRoute,
    Outlet,
    useRouter,
    useRouterState,
} from '@tanstack/react-router'

import { FullPageLoader } from '@/components/custom/loader'
import AccountNav from '@/components/nav/account'
import { DashboardNav } from '@/components/nav/dashboard'
import KBNav from '@/components/nav/knowledgebase'
import Navbar from '@/components/nav/navbar'
import OrgSettingNav from '@/components/nav/organization'

export const Route = createFileRoute('/_dashboard')({
    pendingComponent: FullPageLoader,
    // eslint-disable-next-line no-use-before-define
    component: LayoutComponent,
})

function LayoutComponent() {
    const params = Route.useParams() as any
    const orgId = params.orgId || ''
    const { data: org } = useQuery(singleOrgQueryOptions({ orgId }))

    const loading = useRouterState({ select: (s) => s.status === 'pending' })
    const router = useRouter()

    const match = router.matchRoute({
        to: '/org/$orgId/tickets/$id',

        params: { orgId } as any,
    })
    
    const pathname = router.latestLocation.pathname
    const navTitle = useMemo(() => {
        return pathname === `/org/${orgId}`
            ? 'Dashboard'
            : pathname === `/org/${orgId}/integrations`
              ? 'Integrations'
              : pathname.includes(`/org/${orgId}/tickets`)
                ? 'Support Tickets'
                :pathname.includes(`/org/${orgId}/assistant`)
                ? 'Assistant'
                :pathname.includes(`org/${orgId}/reports`)
                ? 'Reports'
                : ''
    }, [pathname])

    return (
        <div className="flex flex-1 w-full h-screen overflow-hidden">
            <DashboardNav />
            <div>
                <MatchRoute to="/account" fuzzy>
                    {(match) => match && <AccountNav />}
                </MatchRoute>
                <MatchRoute to="/org/$orgId/settings" params={{ orgId }} fuzzy>
                    {(match) => match && <OrgSettingNav />}
                </MatchRoute>

                {org?.member_role !== 'agent' && (
                    <>
                        <MatchRoute
                            to="/org/$orgId/knowledge-base"
                            params={{ orgId }}
                            fuzzy
                        >
                            {(match) => match && <KBNav />}
                        </MatchRoute>
                    </>
                )}
                
            </div>
            <div className="h-screen overflow-auto flex-1 inline-flex flex-col">
                {!match && <Navbar title={navTitle} />}

                {loading ? <FullPageLoader /> : <Outlet />}
            </div>
        </div>
    )
}
