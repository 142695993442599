import { useOrgMutation } from '@/mutations/orgs'
import { CreateOrgFormType, createOrgSchema } from '@/validations/orgs'
import { zodResolver } from '@hookform/resolvers/zod'
import { useNavigate } from '@tanstack/react-router'
import { useForm } from 'react-hook-form'

import { Button } from '@repo/ui/components/ui/button'
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@repo/ui/components/ui/dialog'
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '@repo/ui/components/ui/form'
import { Input } from '@repo/ui/components/ui/input'

export const CreateOrgDialog = (
    props: Parameters<typeof Dialog>[0] & {
        redirect_url?: string
    }
) => {
    const form = useForm<CreateOrgFormType>({
        resolver: zodResolver(createOrgSchema),
        defaultValues: {
            name: '',
        },
    })
    const navigate = useNavigate()
    const { createOrg } = useOrgMutation()

    const { isSubmitting } = form.formState

    const onSubmit = async (values: CreateOrgFormType) => {
        // await apiCreateOrg(values).then(() => {
        //     props?.onOpenChange && props.onOpenChange(false)
        //     navigate({to:'/org/$orgId', {params:}})
        // })

        await createOrg.mutateAsync(values).then(() => {
            props?.onOpenChange && props.onOpenChange(false)
        })
        if (props?.redirect_url) {
            const redirectUrlParts = props?.redirect_url?.split('/')
            const shop = redirectUrlParts?.[redirectUrlParts.length - 1]
            navigate({
                to: props.redirect_url,
                replace: true,
                params: { shop: shop },
            })
        }
    }

    return (
        <Dialog {...props}>
            <DialogContent showClose={!isSubmitting} className="sm:max-w-xl">
                <Form {...form}>
                    <form
                        onSubmit={form.handleSubmit(onSubmit)}
                        className="space-y-8"
                    >
                        <DialogHeader>
                            <DialogTitle>Create Organization</DialogTitle>
                            <DialogDescription>
                                Make changes to your profile here. Click save
                                when you're done.
                            </DialogDescription>
                        </DialogHeader>

                        <FormField
                            control={form.control}
                            name="name"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Business Name</FormLabel>
                                    <FormControl>
                                        <Input
                                            placeholder="Acme Inc."
                                            {...field}
                                        />
                                    </FormControl>

                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <DialogFooter>
                            {/* <Button
                                type="submit"
                                variant={'outline'}
                                onClick={() =>
                                    props?.onOpenChange &&
                                    props.onOpenChange(false)
                                }
                            >
                                Cancel
                            </Button> */}

                            <Button type="submit" disabled={isSubmitting}>
                                Save changes
                            </Button>
                        </DialogFooter>
                    </form>
                </Form>
            </DialogContent>
        </Dialog>
    )
}
