export const checkAllowedAgentRoutes = ({
    orgId,
    pathname,
}: {
    orgId: string
    pathname: string
}) => {
    const allowedPaths = [`/org/${orgId}/tickets`, `/org/${orgId}/settings`]
    let result = false
    if (pathname === `/org/${orgId}`) {
        return true
    }
    for (const path of allowedPaths) {
        if (pathname.indexOf(path) !== -1) {
            result = true
            break
        }
    }
    return result
}
