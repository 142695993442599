import {
    OrgCustomersTable,
    OrgMessagesTable,
    OrgTicketsTable,
} from '@repo/types/db'
import { SingleTicketResult } from '@repo/types/serverResponse'
import {
    infiniteQueryOptions,
    keepPreviousData,
    queryOptions,
} from '@tanstack/react-query'

import { apiQuery } from '@/lib/api'

export type ALLTicketResult = {
    items: Array<
        Pick<
            OrgTicketsTable,
            | 'id'
            | 'channel'
            | 'star'
            | 'status'
            | 'tags'
            | 'created_at'
            | 'updated_at'
            | 'subject'
            | 'analyzed_at'
            | 'satisfaction_score'
            | 'summary'
        > & {
            customer: Pick<OrgCustomersTable, 'id' | 'name'>
            assignee: Pick<OrgCustomersTable, 'id' | 'name'>
        }
    >
    total: number
    page: number
    limit: number
}

export type TicketMessagesResult = {
    items: Array<{
        id: string
        type: OrgMessagesTable['sender_type']
        picture?: string
        name?: string
        ref: string
        messages: Array<Pick<OrgMessagesTable, 'id' | 'data' | 'sent_at'>>
    }>

    hasMore: boolean
}

export const allTicketsQueryOptions = (opts: {
    limit: number
    orgId: string
    page: number
    q?: string
    status?: string
    sort?: string
}) => {
    const searchParams = new URLSearchParams({
        page: String(opts.page),
        limit: String(opts.limit),
        ...(opts.q && { q: opts.q }),
        ...(opts.status && { status: opts.status }),
        ...(opts.sort && { sort: opts.sort }),
    } as Record<string, string>)

    const key = 'tickets'

    return queryOptions<ALLTicketResult, any>({
        queryKey: [key, opts],
        queryFn: ({ signal }) =>
            apiQuery({
                url: `/org/${opts.orgId}/tickets?${searchParams.toString()}`,
                method: 'GET',
                signal,
            }),
        placeholderData: keepPreviousData,
    })
}

export const ticketQueryOptions = (opts: { orgId: string; id: string }) =>
    queryOptions<SingleTicketResult, any>({
        queryKey: ['ticket', opts],
        queryFn: () =>
            apiQuery({
                url: `/org/${opts.orgId}/ticket/${opts.id}`,
                method: 'GET',
            }),
    })

export const ticketMessagesQueryOptions = (opts: { id: string }) => {
    return infiniteQueryOptions<TicketMessagesResult>({
        queryKey: ['ticket-message', opts],
        queryFn: ({ pageParam = 0 }) => {
            const searchParams = new URLSearchParams({
                page: String(pageParam),
                limit: String(10),
            })

            return apiQuery({
                url: `/ticket/${opts.id}/messages?${searchParams.toString()}`,
                method: 'GET',
            })
        },
        initialPageParam: 0,
        getNextPageParam: (lastGroup, group) =>
            (lastGroup.hasMore && group.length) || undefined,
        placeholderData: keepPreviousData,
    })
}
