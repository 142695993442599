import { Link, useParams } from '@tanstack/react-router'
import { formatRelative } from 'date-fns'
import { ArrowUpRight, Ticket } from 'lucide-react'

import { Badge } from '@repo/ui/components/ui/badge'

const DashboardTickets = ({ data }: { data: any }) => {
    const { orgId } = useParams({ from: '/_dashboard/org/$orgId' })
    return (
        <div className="space-y-4 flex flex-col h-full">
            <div className="flex justify-between items-center">
                <h3 className="text-lg">Recent Support Tickets</h3>

                <Link
                    className="text-sm flex items-center gap-1 hover:text-primary"
                    to={`/org/$orgId/tickets`}
                    search={{ page: 0, sort: '' }}
                    params={{ orgId }}
                >
                    <span>View All Tickets</span>
                    <ArrowUpRight width={14} height={14} />
                </Link>
            </div>
            {data && data.length > 0 ? (
                <div>
                    {data.map((item: any, i: number) => (
                        <Link
                            key={i}
                            className="flex  py-3 border-b last:border-0 gap-4"
                            to={`/org/$orgId/tickets/${item.id}` as string}
                        >
                            <div className="flex-1">
                                <p className="text-semibold text-sm line-clamp-1">
                                    {item?.customer || 'Anonymous'}
                                </p>
                                <p className="text-xs text-muted-foreground line-clamp-1">
                                    {item.sender_type.charAt(0).toUpperCase()+item.sender_type.slice(1) || 'Anonymous'} : {item?.message?.text || 'No message yet.'}
                                </p>
                            </div>
                            <div className="w-16">
                            <Badge
                                variant={
                                  item.status === 'open'
                                        ? 'default'
                                        : item.status === 'escalated'
                                        ? 'destructive'
                                        : 'outline'
                                }
                            >
                                {item.status}{' '}
                        </Badge>
                            </div>
                            <div className="w-36">
                                <p className="text-xs text-muted-foreground capitalize">
                                    {formatRelative(
                                        item.updated_at,
                                        new Date()
                                    )}
                                </p>
                            </div>
                            <div>
                                {/* <Button size={'icon'} variant="outline">
                                    <MoreVertical
                                        width={20}
                                        height={20}
                                        strokeWidth={1}
                                    />
                                </Button> */}
                            </div>
                            </Link>
                    ))}
                </div>
            ) : (
                <div className="flex-1 flex flex-col items-center justify-center">
                    <div className="text-center flex flex-col items-center">
                        <Ticket width={40} height={40} strokeWidth={1.5} />
                        <p className="m-auto">No Tickets Yet.</p>
                    </div>
                </div>
            )}
        </div>
    )
}

export default DashboardTickets
