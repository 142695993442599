import {
    ticketMessagesQueryOptions,
    TicketMessagesResult,
    ticketQueryOptions,
} from '@/queries/ticket'
import { OrgMessagesTable } from '@repo/types/db'
import { SocketClientBusinessListeners } from '@repo/types/socket'
import { produce } from 'immer'

import { queryClient } from '@/lib/queryClient'

export const handleTicketCache = (data: OrgMessagesTable) => {
    queryClient.setQueryData(
        ticketMessagesQueryOptions({
            id: data.ticket_id,
        }).queryKey,
        (prev) => {
            return produce(prev, (draft) => {
                if (!draft) return

                const first = draft?.pages[0].items
                const message: TicketMessagesResult['items'][0]['messages'][0] =
                    {
                        id: new Date().getTime().toString(),
                        data: data.data,
                        sent_at: new Date().toISOString(),
                    }

                if (first[0]?.id === data.sender_id) {
                    first[0].messages.push(message)
                } else {
                    first.unshift({
                        id: data.sender_id,
                        type: data.sender_type,
                        ref: new Date().getTime().toString(),
                        messages: [message],
                    })
                }
            })
        }
    )
}

export const ticketListeners = {
    message: ({ data }: SocketClientBusinessListeners<'ticket:message'>) => {
        handleTicketCache(data)
    },
    update: ({
        data: { id, org_id, status, assignee },
    }: SocketClientBusinessListeners<'ticket:update'>) => {
        queryClient.setQueryData(
            ticketQueryOptions({ id, orgId: org_id }).queryKey,
            (prev) => {
                const d = produce(prev, (draft) => {
                    if (!draft) return
                    if (status) draft.ticket.status = status
                    if (assignee) {
                        draft.ticket = {
                            ...draft.ticket,
                            assignee: assignee.id,
                        }

                        draft.assignee = assignee
                    }
                    return draft
                })

                return d
            }
        )
    },
}
