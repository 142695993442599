import { useEffect, useMemo, useState } from 'react'
import {
    Cell,
    Label,
    PieChart as PChart,
    Pie,
    ResponsiveContainer,
    Tooltip,
} from 'recharts'

import { formatNumberToReadable } from '@/lib/number'

import { CustomizedTooltip } from './tooltip'

export const PieChart = ({
    data,
    type
}: {
    data: { name: string; value: number; color: string }[]
    type: string
}) => {
    const [visible, setVisible] = useState(false)

    useEffect(() => {
        const timer = setTimeout(() => {
            setVisible(true)
        }, 1000)
        return () => clearTimeout(timer)
    }, [])

    return useMemo(() => {
        if (!visible) return null
        let sum = 0

        data.forEach((d) => (sum += d.value))

        const pieData =
            sum > 0
                ? data
                : [
                      {
                          name: 'No Data',
                          value: 1,
                          color: 'hsl(var(--border))',
                      },
                  ]

        return (
            <ResponsiveContainer width="100%" height="100%" aspect={1} maxHeight={350}>
                <PChart>
                    <Pie
                        data={pieData}
                        cy={'100%'}
                        cx="50%"
                        innerRadius={'70%'}
                        outerRadius={'100%'}
                        startAngle={180}
                        endAngle={0}
                        paddingAngle={0}
                        dataKey="value"
                        stroke="hsl(var(--border))"
                    >
                        {pieData.map((item, index) => (
                            <Cell
                                key={`cell-${index}`}
                                fill={item.color}
                                className="outline-0"
                            />
                        ))}

                        <Label
                            content={(props) => {
                                const {
                                    viewBox: { cx, cy },
                                } = props as any
                                const positioningProps = {
                                    x: cx,
                                    y: cy,
                                    textAnchor: 'middle',
                                    verticalanchor: 'middle',
                                }

                                return (
                                    <g>
                                        <text
                                            {...positioningProps}
                                            className="font-light text-xs fill-muted-foreground"
                                        >
                                            {type === 'ticket' ? 'Total Tickets' : type === 'message' ? 'Total Messages' : 'Total'}
                                        </text>
                                        <text
                                            {...positioningProps}
                                            y={cy - 20}
                                            className="font-bold text-xl fill-muted-foreground"
                                        >
                                            {formatNumberToReadable(sum)}
                                        </text>
                                    </g>
                                )
                            }}
                        />
                    </Pie>
                    {sum > 0 && <Tooltip content={<CustomizedTooltip />} />}
                </PChart>
            </ResponsiveContainer>
        )
    }, [data, visible])
}
