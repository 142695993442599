import { ticketQueryOptions } from '@/queries/ticket'
import { createFileRoute } from '@tanstack/react-router'

import ErrorPage from '@/components/custom/errorPage'
import { FullPageLoader } from '@/components/custom/loader'

export const Route = createFileRoute('/_dashboard/org/$orgId/tickets/$id/')({
    loader: (opts) => {
        return opts.context.queryClient?.ensureQueryData(
            ticketQueryOptions({
                orgId: opts.params.orgId,
                id: opts.params.id,
            })
        )
    },
    pendingComponent: () => {
        return <FullPageLoader />
    },
    errorComponent: (err) => {
        return <ErrorPage message={(err.error as any).message}></ErrorPage>
    },
})
