import { useEffect, useState } from 'react'
import { useShopifyMutation } from '@/mutations/shopify'
import { singleOrgQueryOptions } from '@/queries/orgs'
import { getShopifyScriptsQuery } from '@/queries/shopify'
import { useGlobalStore } from '@/store/global'
import { CreateOrgFormType, createOrgSchema } from '@/validations/orgs'
import { zodResolver } from '@hookform/resolvers/zod'
import { OrgListTable } from '@repo/types/db'
import { ArrowUpRightSquare } from 'lucide-react'
import { useForm } from 'react-hook-form'

import { queryClient } from '@/lib/queryClient'
import { Button } from '@repo/ui/components/ui/button'
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@repo/ui/components/ui/dialog'

import { FullPageLoader } from '../custom/loader'

export const ShopifyIntegrationDialog = (
    props: Parameters<typeof Dialog>[0] & {
        redirect_url?: string
        // data: any
    }
) => {
    const form = useForm<CreateOrgFormType>({
        resolver: zodResolver(createOrgSchema),
        defaultValues: {
            name: '',
        },
    })

    const { isSubmitting } = form.formState

    // const onSubmit = async (values: CreateOrgFormType) => {
    //     // TODO: detech valid shop name
    //     const { name } = values
    //     // TODO: Shopify outh process begin in new tab.
    // // await apiCreateOrg(values).then(() => {
    // //     props?.onOpenChange && props.onOpenChange(false)
    // //     navigate({to:'/org/$orgId', {params:}})
    // // })

    // await createOrg.mutateAsync(values).then(() => {
    //     props?.onOpenChange && props.onOpenChange(false)
    // })
    // if (props?.redirect_url) {
    //     const redirectUrlParts = props?.redirect_url?.split('/')
    //     const integrationId =
    //         redirectUrlParts?.[redirectUrlParts.length - 1]
    //     navigate({
    //         to: props.redirect_url,
    //         replace: true,
    //         params: { id: integrationId },
    //     })
    // }
    // }
    // const { data } = props

    return (
        <Dialog {...props}>
            <DialogContent showClose={!isSubmitting} className="sm:max-w-xl">
                Redirected to shopify app store after deploying the application
                {/* <Form {...form}>
                    <form
                        onSubmit={form.handleSubmit(onSubmit)}
                        className="space-y-8"
                    >
                        <DialogHeader>
                            <DialogTitle>
                                Integrate with {data.name}
                            </DialogTitle>
                            <DialogDescription>
                                Enter your {data.name} shop URL below.
                            </DialogDescription>
                        </DialogHeader>

                        <FormField
                            control={form.control}
                            name="name"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Shop URL</FormLabel>
                                    <FormControl>
                                        <Input
                                            placeholder="shop.myshopify.com"
                                            {...field}
                                        />
                                    </FormControl>

                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <DialogFooter>
                            {/* <Button
                                type="submit"
                                variant={'outline'}
                                onClick={() =>
                                    props?.onOpenChange &&
                                    props.onOpenChange(false)
                                }
                            >
                                Cancel
                            </Button> */}
                {/* <DialogDescription className="">
                                You will be redirected to {data.name} to
                                authenticate your shop and integrate with your
                                organization.
                            </DialogDescription> */}
                {/* <Button
                                type="submit"
                                disabled={isSubmitting}
                                className="flex flex-row gap-2"
                            >
                                Authenticate Shop
                                <RedirectIcon
                                    style={{
                                        width: '15',
                                        height: '15',
                                        fill: '#ffffff',
                                    }}
                                /> 
                            </Button>
                        </DialogFooter>
                    </form>
                </Form>  */}
            </DialogContent>
        </Dialog>
    )
}

export const ShopifySettingsDialog = (
    props: Parameters<typeof Dialog>[0] & {
        redirect_url?: string
        orgId: OrgListTable['id']
    }
) => {
    const [isScriptedInjected, setIsScriptInjected] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const { orgId } = props

    useEffect(() => {
        // fetch scripts data from backend;
        setIsLoading(true)
        getShopifyScriptsQuery(orgId).then((scripts) => {
            let mainScript = false
            let startScript = false
            scripts?.map((script: any) => {
                const ans: string = 'hello'
                ans.startsWith

                if (
                    script.src.startsWith(
                        import.meta.env.VITE_WEBCHAT_MAIN_SCRIPT_URL
                    )
                ) {
                    mainScript = true
                } else if (
                    script.src.startsWith(
                        import.meta.env.VITE_WEBCHAT_START_SCRIPT_URL
                    )
                ) {
                    startScript = true
                }
            })
            if (mainScript && startScript) {
                setIsScriptInjected(true)
                setIsLoading(false)
            } else {
                setIsScriptInjected(false)
                setIsLoading(false)
            }
        })
    }, [orgId])

    const orgData = queryClient.getQueryData(
        singleOrgQueryOptions({ orgId }).queryKey
    )

    // const { isSubmitting } = form.formState

    // !TODO Utsav fix linting issue and remove "any type and use appropriate ones" (any is been set by kundan temporarily)

    const shopifyData = (orgData as any)?.integrations?.map(
        (integration: any) => {
            if (integration.type === 'shopify') {
                return integration
            }
        }
    )[0]

    const { deleteScripts, injectScript } = useShopifyMutation()

    const handleSciptInjection = () => {
        // Add a loading animation
        if (isScriptedInjected) {
            deleteScripts.mutateAsync({ orgId }).then(() => {
                setIsScriptInjected(false)
            })
        } else {
            injectScript.mutateAsync({ orgId }).then(() => {
                setIsScriptInjected(true)
            })
        }
    }
    if (isLoading) {
        return (
            <Dialog {...props}>
                <DialogContent className="sm:max-w-xl">
                    <FullPageLoader />
                </DialogContent>
            </Dialog>
        )
    }
    return (
        <Dialog {...props}>
            <DialogContent className="sm:max-w-xl">
                {/* <Form {...form}>
                    <form
                        onSubmit={form.handleSubmit(onSubmit)}
                        className="space-y-8"
                    > */}
                <DialogHeader>
                    <DialogTitle>Shopify Settings</DialogTitle>
                    <DialogDescription>
                        Shopify Store settings page
                    </DialogDescription>
                    <DialogDescription className="bg-primary text-white font-medium p-3 my-5 rounded-md flex justify-center">
                        <a
                            href={`https://${shopifyData.shop}`}
                            target="_blank"
                            className="flex flex-row gap-1"
                        >
                            {shopifyData!.shop}{' '}
                            <ArrowUpRightSquare className="w-5" />
                        </a>
                    </DialogDescription>
                    <DialogDescription>
                        <div className="mt-5 flex flex-row gap-3 items-center bg-gray-100 p-2 rounded-xl">
                            <label
                                className="relative inline-block w-11 h-6 rounded-full"
                                title="Turn On/Off Chat Assistant"
                            >
                                <input
                                    type="checkbox"
                                    className="peer opacity-0 w-0 h-0"
                                    defaultChecked={isScriptedInjected}
                                    checked={isScriptedInjected}
                                    onClick={handleSciptInjection}
                                />
                                <span
                                    className="absolute cursor-pointer top-0 left-0 right-0 bottom-0 bg-gray-300 rounded-full duration-300 before:content-[''] before:absolute before:w-4 before:h-4 before:bottom-1 before:left-1 before:rounded-full
                before:bg-white before:duration-300 peer-checked:before:translate-x-5 peer-checked:bg-primary"
                                ></span>
                            </label>
                            <div className="text-lg font-medium text-black-300">
                                Integrate Chat Assistant to Shopify Store
                            </div>
                        </div>
                    </DialogDescription>
                </DialogHeader>

                {/* 
                        <FormField
                            control={form.control}
                            name="name"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Business Name</FormLabel>
                                    <FormControl>
                                        <Input
                                            placeholder="Acme Inc."
                                            {...field}
                                        />
                                    </FormControl>

                                    <FormMessage />
                                </FormItem>
                            )}
                        /> */}

                <DialogFooter>
                    {/* <Button
                                type="submit"
                                variant={'outline'}
                                onClick={() =>
                                    props?.onOpenChange &&
                                    props.onOpenChange(false)
                                }
                            >
                                Cancel
                            </Button> */}

                    <Button type="submit">Save changes</Button>
                </DialogFooter>
                {/* </form> */}
                {/* </Form> */}
            </DialogContent>
        </Dialog>
    )
}

export const OrgShopifyIntegrationAgreeDialog = (
    props: Parameters<typeof Dialog>[0] & {
        redirect_url?: string
        data: {
            orgId: string
            shop: string
            orgName: string
        }
    }
) => {
    const { data } = props
    const { update } = useGlobalStore()
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [submitHover, setSubmitHover] = useState(false)
    const onSubmit = () => {
        try {
            setIsSubmitting(true)

            const { shop, orgId } = data
            update({ org: orgId })
            //TODO: change host to monolith host.

            window.location.href = `${import.meta.env.VITE_MONOLITHIC_URL}/shopify/auth/integrate/org?shop=${shop}&orgId=${orgId}`
        } catch (err) {
            setIsSubmitting(false)
        }
    }

    return (
        <Dialog {...props}>
            <DialogContent showClose={!isSubmitting} className="sm:max-w-xl">
                <DialogHeader>
                    <DialogTitle>Shopify Integration</DialogTitle>
                    <DialogDescription>
                        Are you sure you want to integrate shopify to this
                        organization.
                    </DialogDescription>
                </DialogHeader>

                <div>
                    <div className="grid grid-cols-3">
                        <div className="text-center flex flex-col gap-3">
                            <div>Shopify Shop</div>
                            <img
                                src={'/logo/shopify.svg'}
                                className="w-full h-[5rem]"
                            />
                            <div
                                className={`border p-2 rounded-md ${submitHover || isSubmitting ? 'border-primary' : ''} text-gray-500 transition-all duration-500 ease cursor-not-allowed h-full flex justify-center items-center`}
                            >
                                {data.shop.split('.')[0].length > 7
                                    ? `${data.shop.split('.')[0].slice(0, 3)}...${data.shop.split('.')[0].slice(-2)}.myshopify.com`
                                    : data.shop}
                            </div>
                        </div>
                        <div className="flex flex-row-reverse bottom-4  items-end relative justify-center">
                            <div
                                className={`border ${submitHover || isSubmitting ? 'bg-primary' : 'bg-gray-400'} transition-all duration-500 ease rounded-full absolute -left-1 w-2 h-2 border-gray-400`}
                            ></div>
                            <div
                                className={`border-t-2 h-[5px] w-full absolute left-0.5 right-0.5 border-dashed  transition-all duration-500 ease ${submitHover || isSubmitting ? 'border-primary' : 'border-gray-400'} `}
                            ></div>
                            <div
                                className={`border  ${submitHover || isSubmitting ? 'bg-primary' : 'bg-gray-400'} transition-all duration-500 ease rounded-full absolute -right-1 w-2 h-2 border-gray-400`}
                            ></div>
                        </div>
                        <div className="text-center flex flex-col gap-3">
                            <div>Organization</div>
                            <img
                                src={'/logo/lipy-ico-dark.svg'}
                                className="w-full h-[5rem]"
                            />
                            <div
                                className={`border p-2 rounded-md ${submitHover || isSubmitting ? 'border-primary' : ''} text-gray-500 transition-all duration-500 ease cursor-not-allowed h-full flex justify-center items-center`}
                            >
                                {data.orgName}
                            </div>
                        </div>
                    </div>
                </div>

                <DialogFooter>
                    <Button
                        type="submit"
                        onClick={() => onSubmit()}
                        disabled={isSubmitting}
                        onMouseEnter={() => setSubmitHover(true)}
                        onMouseLeave={() => setSubmitHover(false)}
                    >
                        {isSubmitting
                            ? 'Integrating...'
                            : 'Approve Integration'}
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}
