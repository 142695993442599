import React from 'react'
import { cn } from '@ui/lib/utils'

export interface InputProps
    extends React.TextareaHTMLAttributes<HTMLTextAreaElement> { 
        shouldResize ?:boolean
    }

const TextArea = React.forwardRef<HTMLTextAreaElement, InputProps>(
    ({ className, shouldResize = true, ...props }, ref) => {
        const handleSize = (el: HTMLElement) => {
            if(!shouldResize) return
            el.style.height = '0px'
            el.style.height = `${el.scrollHeight}px`
        }

        React.useEffect(() => {
            if (!props.id || !document) return
            const el = document.getElementById(String(props.id))
            el && handleSize(el)
        }, [props.id])

        return (
            <textarea
                className={cn(
                    'bg-background resize-none text-sm my-auto flex-1 block max-h-[300px] w-full p-2 px-3 py-2 rounded-md border border-input outline-none overflow-auto min-h-10 focus:border-primary',
                    className
                )}
                onInput={(e) => {
                    handleSize(e.currentTarget)
                    props.onChange && props.onChange(e as any)
                }}
                ref={ref}
                {...props}
            />
        )
    }
)

TextArea.displayName = 'TextArea'

export { TextArea }
