import { createFileRoute } from '@tanstack/react-router'
import { z } from 'zod'

import ErrorPage from '@/components/custom/errorPage'
import { FullPageLoader } from '@/components/custom/loader'

export const Route = createFileRoute(
    '/_dashboard/org/$orgId/knowledge-base/intents/$groupId/'
)({
    pendingComponent: () => {
        return <FullPageLoader />
    },
    errorComponent: (err) => {
        return <ErrorPage message={(err.error as any)?.message}></ErrorPage>
    },
    validateSearch: (search) =>
        z
            .object({
                q: z.string().optional().catch(''),
                page: z.number().int().min(0).catch(0),
                sort: z.string().optional().catch(''),
            })
            .parse(search),
})
