import { Bell, BellRing } from 'lucide-react'
import { Button } from '@repo/ui/components/ui/button'
import { Sheet, SheetContent, SheetTrigger } from '@repo/ui/components/ui/sheet'
import { Separator } from '@ui/components/ui/separator'
import { Tabs,TabsList, TabsTrigger, TabsContent } from '@ui/components/ui/tabs'
import { Inbox, ArchiveIcon } from 'lucide-react'
import { Badge } from '@ui/components/ui/badge'
const NotificationNav = () => {
    return (
        <Sheet>
            <SheetTrigger asChild>
                <Button variant="ghost" size={'icon'}>
                    <Bell className="w-5 h-5 text-muted-foreground" />
                </Button>
            </SheetTrigger>
            <SheetContent className="w-[300px] flex flex-col p-4">
                
                    <Tabs defaultValue="inbox">
                        <p className='font-bold pb-2'>Notifications</p>
                        <TabsList className="grid w-full grid-cols-2">
                            <TabsTrigger value="inbox" className='flex space-x-2'>
                                <Inbox className='w-4 h-4'/>
                                <p>Inbox</p>
                                {/* <p className='bg-primary/30 rounded-full pl-1 pr-1 border-primary/40'>9+</p> */}
                                <Badge>0</Badge>
                            </TabsTrigger>
                            <TabsTrigger value="archived" className='flex space-x-1'>
                                <ArchiveIcon className='w-4 h-4'/>
                                <p>Archived</p>
                                {/* <p className='bg-primary/30 rounded-full pl-1 pr-1 border-primary/40'>7</p> */}
                                <Badge>0</Badge>
                            </TabsTrigger>
                        </TabsList>
                    <Separator className='mt-2'/>

                    <TabsContent value='inbox'>
                    <div className='w-[300px] flex flex-col space-y-4 p-4 items-center justify-center text-center h-[500px]'>
                        <span className="h-20 w-20 bg-primary/10 rounded-full flex items-center justify-center border-2 border-primary/40">
                            <BellRing className="w-10 h-10" strokeWidth={1.2} />
                        </span>
                        <div>
                            <p className="text-center mb-2 text-lg">
                                No Notifications Yet.
                            </p>
                            <p className="text-sm text-muted-foreground">
                                It seems you haven't received any notifications in yet.
                            </p>
                        </div>
                        </div>
                    </TabsContent>

                    <TabsContent value='archived'>
                    <div className='w-[300px] flex flex-col space-y-4 p-4 items-center justify-center text-center h-[500px]'>
                        <span className="h-20 w-20 bg-primary/10 rounded-full flex items-center justify-center border-2 border-primary/40">
                            <BellRing className="w-10 h-10" strokeWidth={1.2} />
                        </span>
                        <div>
                            <p className="text-center mb-2 text-lg">
                                Empty Archive
                            </p>
                            <p className="text-sm text-muted-foreground">
                                It seems you haven't archived any notification.
                            </p>
                        </div>
                        </div>
                    </TabsContent>


                    </Tabs>
                
            </SheetContent>
        </Sheet>
    )
}

export default NotificationNav
