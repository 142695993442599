'use client'

import { useState } from 'react'
import { allOrgQueryOptions } from '@/queries/orgs'
import { useGlobalStore } from '@/store/global'
import { useSuspenseQuery } from '@tanstack/react-query'
import { createFileRoute, Link } from '@tanstack/react-router'
import { Plus } from 'lucide-react'

import { formatDateToReadable } from '@/lib/date'
import { Button } from '@repo/ui/components/ui/button'
import { Input } from '@repo/ui/components/ui/input'
import EmptyPage from '@/components/custom/emptyPage'
import ErrorPage from '@/components/custom/errorPage'
import { FullPageLoader } from '@/components/custom/loader'
import Navbar from '@/components/nav/navbar'
import { CreateOrgDialog } from '@/components/org/create'

export const Route = createFileRoute('/orgs')({
    loader: (opts) => {
        return opts.context.queryClient?.ensureQueryData(allOrgQueryOptions())
    },
    pendingComponent: () => {
        return <FullPageLoader />
    },
    errorComponent: (err) => {
        return <ErrorPage message={(err.error as any).message}></ErrorPage>
    },

    // eslint-disable-next-line no-use-before-define
    component: Component,
})

function Component() {
    const [orgDialog, setOrgDialog] = useState(false)
    const { data } = useSuspenseQuery(allOrgQueryOptions())
    const { update } = useGlobalStore()
    const noOrgs =
        data && data?.delegated?.length === 0 && data?.owned?.length === 0

    return (
        <>
            <Navbar />
            <CreateOrgDialog onOpenChange={setOrgDialog} open={orgDialog} />
            <div className="mx-auto max-w-screen-xl w-full py-12 px-8 space-y-8 flex-1 flex-col flex">
                <div>
                    <h2>Organizations</h2>
                    <p className="text-muted-foreground mb-2">
                        List of all organizations you own or have access to.
                    </p>
                </div>
                <div className="flex gap-4 w-full">
                    <Input placeholder="Search organizations..." />
                    <div>
                        <Button onClick={() => setOrgDialog(true)}>
                            <span>Add New...</span>

                            <Plus className="w-5 h-5" />
                        </Button>
                    </div>
                </div>
                {noOrgs ? (
                    <EmptyPage
                        title="No Organizations Yet!"
                        description="It appears you haven't set up any organizations. Let's get started by creating your first organization."
                    >
                        <div className="py-4">
                            <Button onClick={() => setOrgDialog(true)}>
                                <Plus className="w-5 h-5" />

                                <span>Create New Organization</span>
                            </Button>
                        </div>
                    </EmptyPage>
                ) : (
                    <div className="gap-8 grid grid-cols-[repeat(auto-fill,minmax(275px,1fr))]">
                        {data?.owned?.map((item, _i) => (
                            <Link
                                key={item.id}
                                to={`/org/$orgId`}
                                onClick={() => update({ org: item.id })}
                                params={{ orgId: item.id }}
                                className="text-md items-center text-sm p-4 border rounded-md hover:border-primary transition-all ease-in-out duration-300 cursor-pointer"
                            >
                                <p className="text-base">{item.name}</p>
                                <p className="text-muted-foreground text-xs">
                                    Created on{' '}
                                    {formatDateToReadable(item?.created_at)}
                                </p>
                            </Link>
                        ))}
                        {data?.delegated?.map((item) => (
                            <Link
                                key={item.id}
                                to={`/org/$orgId`}
                                onClick={() => update({ org: item.id })}
                                params={{ orgId: item.id }}
                                className="text-md items-center text-sm p-4 border rounded-md hover:border-primary transition-all ease-in-out duration-300 cursor-pointer"
                            >
                                <p className="text-base">{item.name}</p>
                                <p className="text-muted-foreground text-xs">
                                    Created on{' '}
                                    {formatDateToReadable(item?.created_at)}
                                </p>
                            </Link>
                        ))}
                    </div>
                )}
            </div>
        </>
    )
}
