'use client'

import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
} from '@repo/ui/components/ui/accordion'
import { buttonVariants } from '@repo/ui/components/ui/button'
import { cn } from '@repo/ui/lib/utils'
import { Link } from '@tanstack/react-router'
import { LucideIcon } from 'lucide-react'

interface NavProps {
    links: {
        title: string
        label?: string
        icon?: LucideIcon
        url: string
        params?: any
    }[]
    name?: string
}

export const Nav = ({ links, name }: NavProps) => {
    const navLinks = links.map((link, index) => {
        return (
            <Link
                key={index}
                to={link.url}
                params={link.params}
                activeOptions={{ exact: true }}
                activeProps={{
                    className: cn(
                        buttonVariants({
                            variant: 'ghost',
                            size: 'sm',
                        }),
                        'text-primary bg-primary/10 hover:text-primary hover:bg-primary/10 justify-start',
                        name && 'font-light'
                    ),
                }}
                className={cn(
                    buttonVariants({
                        variant: 'ghost',
                        size: 'sm',
                    }),
                    'text-start justify-start',
                    name && 'font-light text-muted-foreground'
                )}
            >
                {link.icon && (
                    <link.icon className="mr-2 h-4 w-4" strokeWidth={1.5} />
                )}
                {link.title}
                {link.label && (
                    <span className={cn('ml-auto text-current')}>
                        {link.label}
                    </span>
                )}
            </Link>
        )
    })

    return (
        <div className="flex flex-col gap-3">
            {name ? (
                <>
                    <Accordion
                        type="single"
                        collapsible
                        className="text-sm font-medium"
                        defaultValue={name}
                    >
                        <AccordionItem
                            value={name}
                            className="border-0 px-2 py-0"
                        >
                            <AccordionTrigger
                                className={cn(
                                    buttonVariants({
                                        variant: 'ghost',
                                        size: 'sm',
                                    }),
                                    'w-full justify-between hover:no-underline'
                                )}
                            >
                                {name}
                            </AccordionTrigger>
                            <AccordionContent className="border-0 p-0 grid gap-1 pt-1">
                                {navLinks}
                            </AccordionContent>
                        </AccordionItem>
                    </Accordion>
                </>
            ) : (
                <nav className={cn('grid gap-2 px-2')}>{navLinks}</nav>
            )}
        </div>
    )
}
