'use client'

import { useState } from 'react'
import { allOrgQueryOptions } from '@/queries/orgs'
import { OrgBasicDataType } from '@repo/lib'
import { useSuspenseQuery } from '@tanstack/react-query'
import { createFileRoute, useParams } from '@tanstack/react-router'
import { ArrowUpRightFromSquare, Plus } from 'lucide-react'

import { formatDateToReadable } from '@/lib/date'
import { Button } from '@repo/ui/components/ui/button'
import { Input } from '@repo/ui/components/ui/input'
import EmptyPage from '@/components/custom/emptyPage'
import ErrorPage from '@/components/custom/errorPage'
import { FullPageLoader } from '@/components/custom/loader'
import Navbar from '@/components/nav/navbar'
import { CreateOrgDialog } from '@/components/org/create'
import { OrgShopifyIntegrationAgreeDialog } from '@/components/org/integration'

function OrgsCard({
    item,
    setShowDialog,
    setDialogData,
}: {
    item: OrgBasicDataType
    setShowDialog?: React.Dispatch<React.SetStateAction<boolean>>
    setDialogData?: React.Dispatch<
        React.SetStateAction<{
            orgId: string
            shop: string
            orgName: string
        }>
    >
}) {
    const showAgreeDialog = async () => {
        if (item.integrations === undefined || item.integrations.length === 0) {
            setDialogData!((prev) => ({
                ...prev,
                orgId: item.id,
                orgName: item.name,
            }))
            setShowDialog!(true)
        }
    }
    return (
        <div
            className={`text-md items-center text-sm p-4 border rounded-md hover:border-primary transition-all ease-in-out duration-300 cursor-pointer`}
            onClick={showAgreeDialog}
        >
            {item.integrations?.map((integration) => {
                if (integration.type == 'shopify') {
                    return (
                        <div className=" w-full bg-red-100 rounded-md p-1 text-center mb-2">
                            Already Linked to {'Shopify'}
                        </div>
                    )
                }
            })}
            {/* <Link
                key={item.id}
                to={`/org/$orgId`}
                onClick={handleOrgSelect}
                params={{ orgId: item.id }}
                disabled={!(item.integrations?.length === 0)}
            > */}
            <p className="text-base">{item.name}</p>
            <p className="text-muted-foreground text-xs">
                Created on {formatDateToReadable(item?.created_at)}
            </p>
            {/* </Link> */}
            {item.integrations != undefined &&
                item.integrations.length != 0 && (
                    <>
                        <hr className="mt-4 " />
                        <div className="grid grid-cols-1 gap-1">
                            {item.integrations?.map((integration, _i) => (
                                <a
                                    className="flex flex-row justify-center items-center w-full   gap-2 mt-2  p-1.5  rounded-md transition-all ease-in-out hover:bg-green-50 hover:text-primary"
                                    href={`https://${integration.shop_name}`}
                                    target="_blank"
                                    key={_i}
                                >
                                    {integration.type === 'shopify' ? (
                                        <img
                                            src={'/logo/shopify.svg'}
                                            className="w-7"
                                        />
                                    ) : null}
                                    {integration.type === 'woocommerce' ? (
                                        <img
                                            src={'/logo/woocommerce.svg'}
                                            className="w-7"
                                        />
                                    ) : null}
                                    <div className="flex flex-row justify-center items-center gap-2 ">
                                        <p className="">Visit Shop</p>
                                        <ArrowUpRightFromSquare className="w-4" />
                                    </div>
                                </a>
                            ))}
                        </div>
                    </>
                )}
        </div>
    )
}

function Component() {
    const [showDialog, setShowDialog] = useState(false)
    const [showCreateDialog, setShowCreateDialog] = useState(false)

    const { shop } = useParams({ from: '/integration/shopify/$shop/' }) as {
        shop: string
    }
    const [dialogData, setDialogData] = useState<{
        orgId: string
        shop: string
        orgName: string
    }>({ orgId: '', orgName: '', shop: shop })

    const { data } = useSuspenseQuery(allOrgQueryOptions())
    // const { update } = useGlobalStore()
    const noOrgs =
        data && data?.delegated?.length === 0 && data?.owned?.length === 0

    return (
        <>
            <Navbar />
            <OrgShopifyIntegrationAgreeDialog
                onOpenChange={setShowDialog}
                open={showDialog}
                data={dialogData}
            />
            <CreateOrgDialog
                open={showCreateDialog}
                onOpenChange={setShowCreateDialog}
                redirect_url={`/integration/shopify/${shop}`}
            />
            <div className="mx-auto max-w-screen-xl w-full py-12 px-8 space-y-8 flex-1 flex-col flex">
                <div>
                    <h2>Organisations</h2>
                    <p className="text-muted-foreground mb-2">
                        List of all organisations you own or have access to.
                    </p>
                </div>
                <div className="flex gap-4 w-full">
                    <Input placeholder="Search organisations..." />
                    <div>
                        <Button onClick={() => setShowCreateDialog(true)}>
                            <span>Add New...</span>

                            <Plus className="w-5 h-5" />
                        </Button>
                    </div>
                </div>
                {noOrgs ? (
                    <EmptyPage
                        title="No Organisations Yet!"
                        description="It appears you haven't set up any organisations. Let's get started by creating your first organisation."
                    >
                        <div className="py-4">
                            <Button onClick={() => setShowCreateDialog(true)}>
                                <Plus className="w-5 h-5" />

                                <span>Create New Organisation</span>
                            </Button>
                        </div>
                    </EmptyPage>
                ) : (
                    <div>
                        <div className="gap-8 grid grid-cols-[repeat(auto-fill,minmax(275px,1fr))]">
                            {/* !TODO Utsav fix linting issue and remove "any type and use appropriate ones" (any is been set by kundan temporarily) */}

                            {data?.owned?.map((item: any, _i) => {
                                if (
                                    item.integrations != undefined &&
                                    item.integrations.length != 0
                                ) {
                                    return null
                                }

                                return (
                                    <OrgsCard
                                        setDialogData={setDialogData}
                                        setShowDialog={setShowDialog}
                                        key={item.id}
                                        item={item}
                                    />
                                )
                            })}

                            {/* !TODO Utsav fix linting issue and remove "any type and use appropriate ones" (any is been set by kundan temporarily) */}

                            {data?.delegated?.map((item: any, _i) => {
                                if (
                                    item.integrations != undefined &&
                                    item.integrations?.length != 0
                                ) {
                                    return null
                                }
                                return (
                                    <OrgsCard
                                        setDialogData={setDialogData}
                                        setShowDialog={setShowDialog}
                                        key={item.id}
                                        item={item}
                                    />
                                )
                            })}
                        </div>
                        <hr className="mt-3 mb-3 border-primary" />
                        <div className="gap-8 grid grid-cols-[repeat(auto-fill,minmax(275px,1fr))]">
                            {/* !TODO Utsav fix linting issue and remove "any type and use appropriate ones" (any is been set by kundan temporarily) */}

                            {data?.owned?.map((item: any, _i) => {
                                if (
                                    item.integrations === undefined ||
                                    item.integrations.length === 0
                                ) {
                                    return null
                                }

                                return <OrgsCard key={item.id} item={item} />
                            })}
                            {/* !TODO Utsav fix linting issue and remove "any type and use appropriate ones" (any is been set by kundan temporarily) */}

                            {data?.delegated?.map((item: any, _i) => {
                                if (
                                    item.integrations === undefined ||
                                    item.integrations.length === 0
                                ) {
                                    return null
                                }
                                return <OrgsCard key={item.id} item={item} />
                            })}
                            <hr />
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}

export const Route = createFileRoute('/integration/shopify/$shop/')({
    loader: (opts) => {
        return opts.context.queryClient?.ensureQueryData(allOrgQueryOptions())
    },
    pendingComponent: () => {
        return <FullPageLoader />
    },
    errorComponent: (err) => {
        return <ErrorPage message={(err.error as any).message}></ErrorPage>
    },
    component: Component,
})
