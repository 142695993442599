import { cn } from '@ui/lib/utils'

export const LoadingBlocks = (props: {
    title?: string
    description?: string
    blocksClass?: string

    className?: string
}) => {
    return (
        <div
            className={cn(
                'm-auto flex flex-col items-center justify-center py-10',
                props.className
            )}
        >
            <div className="flex items-center gap-4 mb-2">
                {[...Array(4)].map((_, i) => (
                    <span
                        key={i}
                        className={cn(
                            'block bg-primary animate-pulse',
                            props.blocksClass || 'w-5 h-5'
                        )}
                        style={{
                            animationDelay: `${(i + 1) * 300}ms`,
                        }}
                    ></span>
                ))}
            </div>
            {props.title && (
                <p className="text-lg font-semibold">{props.title}</p>
            )}
            {props.description && (
                <p className="text-sm text-muted-foreground">
                    {props.description}
                </p>
            )}
        </div>
    )
}
