import { z } from 'zod'

export const orgFormSchema = z.object({
    name: z.string().min(1).max(300),
    category: z.string().max(300),
    picture: z.string().optional(),
    allowed_domains: z.array(
        z.string().regex(
            new RegExp(
                // /^https:\/\/(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(?:\/\S*)?$/
                /^(?:https:\/\/(?:localhost(?::\d+)?|\S+\.[a-zA-Z]{2,})(?:\/\S*)?|http:\/\/localhost(?::\d+)?(?:\/\S*)?)$/
            )
        )
    ),
})

export type OrgFormValues = z.infer<typeof orgFormSchema>

export const createOrgSchema = z.object({
    name: z.string().min(2).max(100),
})

export type CreateOrgFormType = z.infer<typeof createOrgSchema>

export const inviteOrgSchema = z.object({
    email: z.string().email(),
    type: z.enum(['admin', 'agent']),
})

export type InviteOrgFormValues = z.infer<typeof inviteOrgSchema>

export const invitationSchema = z.object({
    org: z.string(),
    inviteId: z.string(),
    action: z.enum(['accept', 'reject']),
})

export type InvitationSchemaValues = z.infer<typeof invitationSchema>

// export type ShopifyShopURLValidation =
