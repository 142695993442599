import { createFileRoute } from '@tanstack/react-router'

import ErrorPage from '@/components/custom/errorPage'
import { FullPageLoader } from '@/components/custom/loader'

export const Route = createFileRoute(
    '/_dashboard/org/$orgId/reports/'
)({
    pendingComponent: () => {
        return <FullPageLoader />
    },
    errorComponent: (err) => {
        return <ErrorPage message={(err.error as any)?.message}></ErrorPage>
    }
})